import React from 'react';

type Props = {
  brand: 'visa' | 'mastercard' | 'amex' | 'discover';
  size?: string;
  className?: string;
};

const CardBrandIcon: React.FC<Props> = ({ brand, size = '26', className = '' }) => (
  <div className={`flex items-center br-2px overflow-hidden ${className}`}>
    {brand === 'visa' && (
      <svg height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 46">
        <defs />
        <g fill="none" fillRule="evenodd">
          <path
            fill="#16191d"
            d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          />
          <g fill="#ffffff">
            <path d="M30.022 29.744h-3.539l2.214-13.332h3.539zM42.854 16.738a8.929 8.929 0 00-3.175-.568c-3.495 0-5.957 1.816-5.972 4.41-.029 1.916 1.762 2.98 3.102 3.618 1.37.652 1.835 1.078 1.835 1.66-.014.893-1.106 1.305-2.125 1.305-1.414 0-2.17-.213-3.322-.71l-.466-.213-.495 2.993c.83.368 2.36.695 3.948.71 3.714 0 6.132-1.788 6.16-4.553.015-1.518-.931-2.681-2.97-3.631-1.239-.61-1.997-1.022-1.997-1.646.014-.567.641-1.148 2.039-1.148a6.152 6.152 0 012.637.51l.32.142.481-2.88z" />
            <path
              fillRule="nonzero"
              d="M47.559 25.02c.291-.765 1.413-3.73 1.413-3.73-.015.03.29-.78.466-1.276l.247 1.15s.67 3.19.816 3.857h-2.942zm4.369-8.608H49.19c-.844 0-1.486.24-1.85 1.106l-5.258 12.226h3.714l.743-2h4.545c.101.468.422 2 .422 2h3.278l-2.856-13.332z"
            />
            <path d="M23.526 16.412l-3.466 9.091-.38-1.844c-.64-2.127-2.65-4.439-4.893-5.588l3.175 11.659h3.743l5.564-13.318h-3.743z" />
            <path d="M16.84 16.412h-5.694l-.059.27c4.443 1.106 7.385 3.772 8.594 6.978l-1.238-6.127c-.204-.852-.83-1.093-1.602-1.121z" />
          </g>
        </g>
      </svg>
    )}
    {brand === 'mastercard' && (
      <svg height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 46">
        <defs />
        <g fill="none" fillRule="evenodd">
          <path
            fill="#16191d"
            d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          />
          <path fill="#FF5F00" d="M27.658 14.525h10.503v16.918H27.658z" />
          <path
            fill="#EB001B"
            d="M28.74 22.985a10.687 10.687 0 014.166-8.457c-4.473-3.467-10.896-2.962-14.755 1.16a10.65 10.65 0 000 14.598c3.86 4.121 10.282 4.626 14.755 1.16a10.687 10.687 0 01-4.167-8.46z"
          />
          <path
            fill="#F79E1B"
            fillRule="nonzero"
            d="M49.536 30.083v-.704h.167v-.145h-.397v.145h.157v.704h.073zm.77 0v-.849h-.12l-.14.606-.14-.606h-.12v.849h.087v-.643l.13.552h.09l.13-.552v.643h.083z"
          />
          <path
            fill="#F79E1B"
            d="M50.558 22.985c0 4.12-2.385 7.877-6.142 9.677a11.034 11.034 0 01-11.51-1.22 10.705 10.705 0 004.168-8.458c0-3.301-1.536-6.42-4.168-8.46a11.034 11.034 0 0111.51-1.219c3.757 1.8 6.142 5.558 6.142 9.677v.003z"
          />
        </g>
      </svg>
    )}
    {brand === 'amex' && (
      <svg height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 46">
        <defs />
        <g fill="none" fillRule="evenodd">
          <path
            fill="#16191d"
            d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          />
          <path
            fill="#ffffff"
            d="M12.82 34.79V24.53h10.923l1.172 1.519 1.21-1.52h39.646v9.554s-1.037.697-2.236.707H41.582l-1.32-1.617v1.617h-4.33v-2.76s-.592.385-1.87.385h-1.474v2.375h-6.555l-1.17-1.552-1.189 1.552H12.821zM.067 16.796l2.463-5.712h4.26l1.397 3.2v-3.2h5.295l.832 2.312.807-2.312h23.77v1.162s1.249-1.162 3.302-1.162l7.712.026 1.374 3.158v-3.184h4.431l1.22 1.814v-1.814H61.4v10.26H56.93l-1.17-1.819v1.82h-6.51l-.654-1.618h-1.75l-.644 1.618h-4.415c-1.767 0-2.897-1.14-2.897-1.14v1.14h-6.657l-1.321-1.618v1.618H6.157l-.654-1.618H3.76l-.65 1.618H.066v-4.55z"
          />
        </g>
      </svg>
    )}
    {brand === 'discover' && (
      <svg height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 46">
        <defs />
        <g fill="none" fillRule="evenodd">
          <path
            fill="#16191d"
            d="M0 3c0-1.657 1.348-3 3.007-3h59.986A3.01 3.01 0 0166 3v40c0 1.657-1.348 3-3.007 3H3.007A3.01 3.01 0 010 43V3z"
          />
          <g transform="matrix(1 0 0 -1 8.168 32.859)">
            <mask id="b" fill="#ffffff">
              <path />
            </mask>
          </g>
        </g>
      </svg>
    )}
  </div>
);

export default CardBrandIcon;
