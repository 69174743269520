import { useUpcomingSessions } from '../../hooks/Sessions/useUpcomingSessions';
import { Round } from '../../types/RoundTypes';
import ViewScheduleListItem from '../../components/ViewSchedule/ViewScheduleListItem';
import UnusedGrantsCountMessage from '../../components/UnusedGrantsCountMessage/UnusedGrantsCountMessage';
import TextPanel from '../../components/CompanyIntroductions/TextPanel';
import EmptyListExplainerPanel from '../../components/EmptyListExplainerPanel/EmptyListExplainerPanel';
import RoundInformationCard from '../../components/RoundInformationCard/RoundInformationCard';

const UpcomingPage = () => {
  const { isLoading, isError, data: rounds } = useUpcomingSessions();

  if (isLoading) {
    return <TextPanel text="Loading ..." />;
  }

  if (isError) {
    return <>Ooops something went wrong.</>;
  }

  if (!rounds.length) {
    return (
      <>
        <EmptyListExplainerPanel
          heading="Upcoming interviews show up here"
          description="You have no upcoming interviews."
        />
        <UnusedGrantsCountMessage />
      </>
    );
  }
  return (
    <>
      <RoundInformationCard />
      <ul className="sm:grid sm:gap-4 sm:mb-4">
        {rounds.map((round: Round, i: number) => (
          <ViewScheduleListItem
            round={round}
            hideActions={round.details.kind === 'CompanyRound' && round.details.sourceType === 'standalone'}
            key={i}
          />
        ))}
      </ul>
      <UnusedGrantsCountMessage />
    </>
  );
};

export default UpcomingPage;
