import { useQuery } from '@tanstack/react-query';

import { TBeyondCTCIBookManifest } from '../../types/BeyondCTCIBookManifest';
import axios from '../../utils/axios';

export const useFetchManifest = () =>
  useQuery({
    queryKey: ['manifest'],
    queryFn: () =>
      axios.get(`api/beyond-ctci/authored-content-index`).then((response) => response.data as TBeyondCTCIBookManifest),
  });
