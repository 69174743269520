import { useIntersectionObserver } from 'usehooks-ts';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { useEffect } from 'react';
import { TMarkdownStrapiComponent } from '../../../types/MarkdownPanelStrapiComponent';
import { MarkdownPanel } from '@interviewingio/iio-react-component-lib';
import { useToasts } from '../../../components/ToastContext';

const Markdown = ({ dynamicZoneItem }: { dynamicZoneItem: TMarkdownStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.8 });
  const { dispatch } = useToasts();

  const onContentCopied = (data: { content: string; originalEvent: React.MouseEvent<HTMLButtonElement> }) => {
    const secondaryMessage = data.content && `"${data.content?.slice(0, 25)}..."`;
    try {
      dispatch({
        type: 'addToast',
        toastContent: {
          displayTimeout: 3000,
          primaryMessage: 'Copied to your clipboard',
          secondaryMessage,
        },
      });
    } catch {}
  };

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <MarkdownPanel
        title={dynamicZoneItem.title}
        slug={dynamicZoneItem.slug}
        onContentCopied={onContentCopied}
        content={dynamicZoneItem.content}
      />
    </div>
  );
};

export default Markdown;
