import { Disclosure } from '../../../components/DisclosureCustom/DisclosureCustom';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import { TBeyondCTCIBookTableOfContents } from '../../../types/BeyondCTCIBookTableOfContents';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import TableOfContentsPartTrigger from './TableOfContentsPartTrigger';
import TableOfContentsPartPanel from './TableOfContentsPartPanel';

interface ITableOfContentsAccordionProps {
  tableOfContents: TBeyondCTCIBookTableOfContents;
}

export const PartHasChapters = (bookPart: TBeyondCTCIBookPart) => bookPart.attributes.book_chapters.data.length > 0;

const TableOfContentsAccordion = ({ tableOfContents }: ITableOfContentsAccordionProps) => {
  const { currentPartId } = useBCTCIContext();

  return (
    <ul>
      {tableOfContents.map((bookPart: TBeyondCTCIBookPart, partIndex: number) => (
        <li className="pt-1" key={partIndex}>
          <Disclosure defaultOpen={bookPart.id === currentPartId}>
            {({ open: openL1 }) => (
              <div className={`flex flex-col px-3 ${openL1 ? 'bg-white shadow-lg' : ''}`}>
                <Disclosure.Button className={`flex flex-row py-1 ${!PartHasChapters(bookPart) ? 'disabled' : ''}`}>
                  <TableOfContentsPartTrigger bookPart={bookPart} open={openL1} />
                </Disclosure.Button>

                <Disclosure.Panel className="pb-2">
                  <TableOfContentsPartPanel bookPart={bookPart} />
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </li>
      ))}
    </ul>
  );
};

export default TableOfContentsAccordion;
