import { Control, Controller } from 'react-hook-form';

const BooleanTextToggle = ({
  name,
  control,
  label,
  bgColor,
  textColor,
}: {
  label: string;
  control: Control;
  name: string;
  bgColor: string;
  textColor: string;
}) => {
  const renderToggle = ({
    field: { value, onChange },
  }: {
    field: { value: boolean; onChange: (arg0: boolean) => void };
  }) => {
    const backgroundColor = value ? bgColor : 'bg-gray-100';
    const color = value ? textColor : 'black';

    return (
      <span
        onClick={() => onChange(!value)}
        className={`cursor-pointer px-3 py-1 ${backgroundColor} ${color} text-base text-center`}
      >
        {label}
      </span>
    );
  };

  return <Controller name={name} control={control} render={renderToggle} />;
};

export default BooleanTextToggle;
