import axios from '../../utils/axios';
import { useQuery } from '@tanstack/react-query';
import { Round } from '../../types/RoundTypes';

export type TUpcomingRounds = Round[];

// Replicates: RoundService.getForUser(null, true);

// TODO: Decide how best to handle "completed" and "ditched" rounds

/**
 * Note: Sessions / Rounds are synonymous as the business
 * has expanded from scheduling "rounds" with companies and
 * our scope covers pairing up people for "sessions" with varying
 * purposes such as mentorship, coaching, interview practice, etc.
 */
export const useUpcomingSessions = () =>
  useQuery({
    queryKey: ['upcoming-sessions'],
    queryFn: (): Promise<TUpcomingRounds> =>
      axios
        .get('/api/rounds/forUser', {
          params: {
            startDate: new Date(),
            joinableOnly: true,
          },
        })
        .then((response) => response.data),
    refetchInterval: 60 * 1000,
  });
