import { Round } from '../../types/RoundTypes';

type CompanyRoundInterviewProps = {
  round: Round;
  showCompany: boolean;
};

export default function CompanyRoundInterview({ round, showCompany }: CompanyRoundInterviewProps) {
  return (
    <span>
      {round._roleAllowed === 'interviewee' && (
        <span>
          <strong>{round.details.companyName}</strong> is interviewing you
          {round._positionTitleWithLocations && (
            <span>
              for the role of <strong>{round._positionTitleWithLocations}</strong>
            </span>
          )}
        </span>
      )}

      {round._roleAllowed === 'interviewer' && (
        <span>
          You are interviewing <strong>{round._partnerName}</strong>{' '}
          {round._positionTitleWithLocations && (
            <span>
              for the role of <strong>{round._positionTitleWithLocations} </strong>
              {showCompany && (
                <span>
                  at <strong>{round.details.companyName}</strong>
                </span>
              )}
            </span>
          )}
          {!round._positionTitleWithLocations && showCompany && (
            <span>
              for
              <strong>{round.details.companyName}</strong>
            </span>
          )}
        </span>
      )}
    </span>
  );
}
