import IconPill from '../IconPill/IconPill';

type PendingRoundProps = {
  companyName: string;
};
export default function PendingRound({ companyName }: PendingRoundProps) {
  return (
    <span className="flex items-center opacity-50">
      <IconPill pro={true} alertType="success" fixed={true}>
        Pending
      </IconPill>{' '}
      Interview with {companyName}
    </span>
  );
}
