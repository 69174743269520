const IN_INTERVIEWER_DASH_REGEX = /^\/dashboard\/interviewer\/*$/;
const IN_INTERVIEWEE_DASH_REGEX = /^\/dashboard\/interviewee\/*$/;
const IN_DEFERRALS_DASH_REGEX = /^\/dashboard\/interviewee\/pay-later-program/;
const IN_UPCOMING_REGEX = /^\/schedule\/view-schedule/;
const IN_HISTORY_REGEX = /^\/history/;
const IN_FEEDBACK_REGEX = /^\/feedback/;
const IN_SET_AVAILABILITY_REGEX = /^\/schedule\/set-availability/;
const IN_CLAIM_REGEX = /^\/claim/;
const IN_SUPER_ADMIN_REGEX = /^\/admin/;
const IN_FAQ_REGEX = /^\/faq/;
const IN_SETTINGS_REGEX = /^\/settings/;
const IN_SANDBOX_REGEX = /^\/interview\/playground/;
const IN_SHOWCASE_REGEX = /^\/showcase/;
const IN_REFERRALS_REGEX = /^\/referrals/;
const IN_LABS_REGEX = /^\/labs/;
const IN_SALARY_NEGOTIATION_REGEX = /^\/salary-negotiation/;
const IN_VIEW_BOOK_REGEX = /^\/beyond-ctci/;
const IN_COMPANY_INTRODUCTIONS_REGEX = /^\/company-introductions/;
const IN_DEDICATED_COACHING_REGEX = /^\/dashboard\/interviewee\/dedicated-coaching/;
const IN_DEFERRALS_REGEX = /^\/deferrals/;
const IN_AI_INTERVIEWER = /^\/interview-ai/;

export class RouteHelper {
  static locationMatchesRegex(location: Location, regex: RegExp): boolean {
    return location.pathname.match(regex) !== null;
  }

  static isInterviewerDashboard(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_INTERVIEWER_DASH_REGEX);
  }

  static isIntervieweeDashboard(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_INTERVIEWEE_DASH_REGEX);
  }

  static isDeferralsDashboard(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_DEFERRALS_DASH_REGEX);
  }

  static isUpcoming(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_UPCOMING_REGEX);
  }

  static isHistory(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_HISTORY_REGEX);
  }

  static isFeedback(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_FEEDBACK_REGEX);
  }

  static isSetAvailability(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_SET_AVAILABILITY_REGEX);
  }

  static isClaim(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_CLAIM_REGEX);
  }

  static isSuperAdmin(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_SUPER_ADMIN_REGEX);
  }

  static isFAQ(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_FAQ_REGEX);
  }

  static isSettings(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_SETTINGS_REGEX);
  }

  static isSandbox(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_SANDBOX_REGEX);
  }

  static isShowcase(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_SHOWCASE_REGEX);
  }

  static isReferrals(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_REFERRALS_REGEX);
  }

  static isLabs(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_LABS_REGEX);
  }

  static isSalaryNegotiation(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_SALARY_NEGOTIATION_REGEX);
  }

  static isViewBook(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_VIEW_BOOK_REGEX);
  }

  static isCompanyIntroductions(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_COMPANY_INTRODUCTIONS_REGEX);
  }

  static isDedicatedCoaching(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_DEDICATED_COACHING_REGEX);
  }

  static isDeferrals(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_DEFERRALS_REGEX);
  }

  static isAiInterviewer(location: Location): boolean {
    return this.locationMatchesRegex(location, IN_AI_INTERVIEWER);
  }

  static isHome(location: Location): boolean {
    return (
      this.locationMatchesRegex(location, IN_INTERVIEWEE_DASH_REGEX) ||
      this.locationMatchesRegex(location, IN_INTERVIEWER_DASH_REGEX)
    );
  }
}
