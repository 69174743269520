import React from 'react';

import { useCanViewBeyondCTCIBook } from '../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { useBeyondCTCIBookTableOfContents } from '../../hooks/BeyondCTCIBook/useBeyondCTCIBookTableOfContents';
import { TBeyondCTCIBookTableOfContents } from '../../types/BeyondCTCIBookTableOfContents';
import '../../pages/BeyondCTCIBook/BeyondCTCIBookStyles.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import AiInterviewerCTA from './AiInterviewerCTA';
import ContactPanel from './ContactPanel';
import TableOfContentsAccordion from './Accordion/TableOfContentsAccordion';

const BeyondCTCIBookTableOfContentsContent = ({
  tableOfContents,
  drawerOpen,
  setDrawerOpen,
}: {
  tableOfContents: TBeyondCTCIBookTableOfContents;
  drawerOpen: boolean;
  setDrawerOpen?: (value: boolean) => void;
}) => {
  const handleDrawerClick = () => {
    setDrawerOpen?.(!drawerOpen);
  };

  return (
    <aside>
      <div className="xl:fixed">
        {drawerOpen && (
          <ChevronLeftIcon
            onClick={handleDrawerClick}
            className="absolute -right-8 top-4 z-10 hidden h-8 cursor-pointer rounded bg-white px-1 py-1 shadow-lg xl:block"
          />
        )}
        {!drawerOpen && (
          <ChevronRightIcon
            onClick={handleDrawerClick}
            className="my-4 ml-3 hidden h-8 min-w-fit cursor-pointer rounded bg-white px-1 py-1 shadow-lg xl:block"
          />
        )}

        <div
          className={`bg-bctci-gray-sidebar-default hide-scrollbar h-screen flex flex-col overflow-y-scroll xl:max-w-[324px] ${
            !drawerOpen && 'hidden'
          }`}
        >
          <div className="w-full px-4 py-3">
            <TableOfContentsAccordion tableOfContents={tableOfContents} />
          </div>

          <ContactPanel />
          <div className="mb-24 w-full" />

          <div className="sticky bottom-20 hidden md:block mt-auto">
            <AiInterviewerCTA />
          </div>

          {/* Fixed position div to hide the "hole" under the ToC sidebar to accommodate site footer */}
          <div className="bg-bctci-gray-sidebar-default fixed bottom-0 h-[31px] w-[324px]" />
        </div>
      </div>
    </aside>
  );
};

export interface BeyondCTCIBookTableOfContentsSidebarProps {
  drawerOpen: boolean;
  setDrawerOpen?: (value: boolean) => void;
}

const BeyondCTCIBookTableOfContentsSidebar = ({
  drawerOpen,
  setDrawerOpen,
}: BeyondCTCIBookTableOfContentsSidebarProps) => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const { data: bookTableOfContents, isLoading, isFetched } = useBeyondCTCIBookTableOfContents();

  if (!isUserAllowed) return null;

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {isFetched && (
        <BeyondCTCIBookTableOfContentsContent
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          tableOfContents={bookTableOfContents}
        />
      )}
    </>
  );
};

export default BeyondCTCIBookTableOfContentsSidebar;
