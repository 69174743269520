import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { TCodeBlockStrapiComponent } from '../../../types/CodeBlockPanelStrapiComponent';
import { CodeBlockPanel } from '@interviewingio/iio-react-component-lib';

const CodeBlock = ({ dynamicZoneItem }: { dynamicZoneItem: TCodeBlockStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.8 });

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <CodeBlockPanel
        title={dynamicZoneItem.title}
        slug={dynamicZoneItem.slug}
        syntaxHighlighting={dynamicZoneItem.syntaxHighlighting}
        sourceCode={dynamicZoneItem.sourceCode}
        caption={dynamicZoneItem.caption}
      />
    </div>
  );
};

export default CodeBlock;
