import { useParams } from 'react-router';

import { ProblemPanel } from '@interviewingio/iio-react-component-lib';
import { useFetchManifest } from '../../../hooks/BeyondCTCIBook/useFetchManifest';
import { useProblemArtifactQuery } from '../../../hooks/BeyondCTCIBook/useProblemArtifactQuery';

interface SolutionPageProps {
  problemSlug: string;
}

const SolutionPage = ({ problemSlug }: SolutionPageProps) => {
  const { data: manifest } = useFetchManifest();

  if (!manifest) {
    return <>Loading</>;
  }

  const manifestProblem = manifest.problems[problemSlug];

  if (!manifestProblem) {
    return <>Problem not found</>;
  }

  return (
    <div className="p-4">
      <ProblemPanel
        title={manifestProblem.name}
        slug={problemSlug}
        useProblemArtifactQuery={useProblemArtifactQuery}
        solutionLangs={manifestProblem.solution_langs}
        defaultSolutionVisible={true}
      />
    </div>
  );
};

export const SolutionPageRoute = () => {
  const { problemSlug } = useParams<{ problemSlug: string }>();
  return <SolutionPage problemSlug={problemSlug} />;
};

export default SolutionPage;
