import axiosInstance from '../utils/axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { OrganizationType } from '../components/Scheduler/Types';

export const useOrg = (orgId: string, options?: UseQueryOptions<OrganizationType>) =>
  useQuery({
    queryKey: ['org', orgId],
    queryFn: (): Promise<OrganizationType> =>
      axiosInstance
        .get('/api/employer/getGeneric/' + orgId, {
          params: {},
        })
        .then((response) => response.data),
    enabled: !!orgId,
    ...options,
  });
