import { Round } from '../../types/RoundTypes';

type ShameWarningProps = {
  round: Round;
};
const ShameWarning = ({ round }: ShameWarningProps) => (
  <div className={round.details.kind === 'CompanyRound' ? '_banner-danger' : '_banner-warning'}>
    <p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-2 mx-auto my-0 h-5 text-center"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
        />
      </svg>
      <strong>This is short notice.</strong> Don’t forget there’s a real person on the other end who’s preparing for
      this interview. In the future, please try to do it at least 24 hours in advance.
    </p>
  </div>
);

export default ShameWarning;
