import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import { FONT_FAMILY, H2 } from '@interviewingio/iio-react-component-lib';
import BeyondCTCIBookTableOfContentsSidebar from './BeyondCTCIBookTableOfContentsSidebar';

const TableOfContentsMobileContainer = () => (
  <Disclosure>
    {({ open }) => (
      <div>
        <Disclosure.Button className="bg-bctci-gray-sidebar-default w-full justify-between">
          <div className="flex justify-between px-4 py-3">
            <H2 fontFamily={FONT_FAMILY.HEADING} color="text-black">
              Table of Contents
            </H2>
            {!open && <ChevronDownIcon className="ml-auto mt-2 h-6 w-6" />}
            {open && <XIcon className="ml-auto mt-2 h-6 w-6" />}
          </div>
        </Disclosure.Button>

        <Disclosure.Panel className="bg-bctci-gray-sidebar-default">
          <BeyondCTCIBookTableOfContentsSidebar drawerOpen={true} />
        </Disclosure.Panel>
      </div>
    )}
  </Disclosure>
);

export default TableOfContentsMobileContainer;
