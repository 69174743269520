import { InterviewReplayLauncherPanel } from '@interviewingio/iio-react-component-lib';
import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { TInterviewReplayLauncherPanelStrapiComponent } from '../../../types/InterviewReplayLauncherPanelStrapiComponent';

const InterviewReplayLauncher = ({
  dynamicZoneItem,
}: {
  dynamicZoneItem: TInterviewReplayLauncherPanelStrapiComponent;
}) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.1 });

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  const getReplayData = () => {
    const interview = dynamicZoneItem.interviewData;

    return Promise.resolve({
      editorContent: interview.editorContents,
      language: ['python2', 'python3', 'python'].includes(interview.language) ? 'python' : interview.language,
      duration: new Date(interview.endTime).getTime() - new Date(interview.startTime).getTime(),
      isHire: interview._success,
      feedback: {
        interviewee: interview.interviewee,
        interviewer: interview.interviewer,
      },
      startTime: interview.startTime,
      roundFocus: window.CONFIG.const.ROUND_FOCUS_DISPLAY_VALUES[interview.round.focus],
      orgDetails: interview._org,
      numAnnotations: interview.numAnnotations?.MOMENT || 0,
    });
  };

  return (
    <div ref={ref}>
      <InterviewReplayLauncherPanel
        title={dynamicZoneItem.title}
        slug={dynamicZoneItem.slug}
        getReplayData={getReplayData}
        friendlyId={dynamicZoneItem.friendlyId}
        replayStartAt={dynamicZoneItem.replayStartAt}
        summaryLabel={dynamicZoneItem.summaryLabel}
        question={dynamicZoneItem.question}
        candidate={dynamicZoneItem.candidate}
        interviewer={dynamicZoneItem.interviewer}
        role={dynamicZoneItem.role}
        whatYouWillHear={dynamicZoneItem.whatYouWillHear}
        timestamps={dynamicZoneItem.timestamps}
      />
    </div>
  );
};

export default InterviewReplayLauncher;
