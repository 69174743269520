import { useState } from 'react';
import { useToasts } from '../ToastContext';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TUser } from '../../types/User';
import axios from '../../utils/axios';
import CreatableSelect from 'react-select/creatable';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useUser } from '../../userContext';

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

const InterviewerAvailabilityCTA = () => (
  <div className="w-100">
    <em>
      Set your availability and interview types{' '}
      <a className="font-medium underline" href="/schedule/set-availability?orgId=practice">
        here
      </a>
      .
    </em>
  </div>
);

interface UserPreferencesForm {
  firstName: string;
  lastName: string;
  company: {
    name: string;
    discoveryId: string;
  };
  avoidMatch: boolean;
  isHiringManager: boolean;
  types: {
    yearsExperience: number;
  };
  rolePreferred: string;
}

const UserPreferences = () => {
  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [serverErrors, setServerErrors] = useState<ResponseError[]>();
  const { dispatch } = useToasts();
  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserPreferencesForm>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      rolePreferred: user?.rolePreferred,
      types: user?.types,
      company: user?.company,
      avoidMatch: user?.company?.avoidMatch,
      isHiringManager: user?.company?.isHiringManager,
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });
  const rolePreferred = watch('rolePreferred');

  const { data: searchResults } = useQuery(
    ['company-discovery', searchTerm],
    () =>
      axios
        .get('/api/companyDiscovery/search', { params: { prefix: searchTerm } })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response) => response.data.map((item: any) => ({ name: item.name, discoveryId: item._id }))),
    {
      enabled: searchTerm.length >= 2,
    }
  );

  const updateUserPreferencesMutation = useMutation(
    async (data: UserPreferencesForm): Promise<{ data: TUser }> => axios.put('api/users/me/settings-user-prefs', data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Your user preferences have been updated.',
          },
        });
        setServerErrors([]);
        reset({}, { keepValues: true });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: UserPreferencesForm) => {
    updateUserPreferencesMutation.mutate(data);
  };

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="flex flex-col">
        <div className="mb-2">
          <p className="text-sm/6 font-semibold text-gray-900">Contact Info</p>
          <p className="text-sm/6 text-gray-600 p-0 m-0">
            So we can contact you. Other users will see your info <strong>if and only if</strong> you both agree to
            deanonymize.
          </p>
        </div>
        <div className="sm:col-span-3">
          <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
            First name
          </label>
          <div>
            <input
              type="text"
              name="firstName"
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              {...register('firstName', { required: 'First Name is required.' })}
            />
            <div className="h-4">
              <p className="text-sm text-red-500">{errors.firstName && errors.firstName.message}</p>
            </div>
          </div>
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="last-name" className="block text-sm/6 font-medium text-gray-900">
            Last name
          </label>
          <div>
            <input
              type="text"
              name="lastName"
              id="last-name"
              autoComplete="family-name"
              className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              {...register('lastName', {
                required: 'Last Name is required.',
                minLength: {
                  value: 2,
                  message: 'Last Name must be at least 2 characters.',
                },
              })}
            />
            <p className="h-4 text-sm text-red-500">{errors.lastName && errors.lastName.message}</p>
          </div>
        </div>
      </div>
      <div className="border-b border-gray-900/10 pb-6">
        <div className="space-y-10">
          <fieldset className="border-none">
            <legend className="text-sm/6 font-semibold text-gray-900">Preferences</legend>
            <p className="text-sm/6 text-gray-600">So we can give you the best experience on the platform.</p>
            <div className="mt-2 space-y-1">
              <div className="flex items-center gap-x-3">
                <input
                  id="get-interviewed"
                  value="interviewee"
                  name="rolePreferred"
                  type="radio"
                  className="size-4 forced-colors:appearance-auto forced-colors:before:hidden relative appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 [&:not(:checked)]:before:hidden"
                  {...register('rolePreferred', { required: 'rolePreferred is required.' })}
                />
                <label htmlFor="get-interviewed" className="block text-sm/6 font-medium text-gray-900">
                  Get Interviewed
                </label>
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  id="be-the-interviewer"
                  value="interviewer"
                  name="rolePreferred"
                  type="radio"
                  className="size-4 forced-colors:appearance-auto forced-colors:before:hidden relative appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 [&:not(:checked)]:before:hidden"
                  {...register('rolePreferred', { required: 'rolePreferred is required.' })}
                />
                <label htmlFor="be-the-interviewer" className="block text-sm/6 font-medium text-gray-900">
                  Be the Interviewer
                </label>
              </div>
              <div className="flex items-center gap-x-3">
                <input
                  id="do-both"
                  value="both"
                  name="rolePreferred"
                  type="radio"
                  className="size-4 forced-colors:appearance-auto forced-colors:before:hidden relative appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 [&:not(:checked)]:before:hidden"
                  {...register('rolePreferred', { required: 'rolePreferred is required.' })}
                />
                <label htmlFor="do-both" className="block text-sm/6 font-medium text-gray-900">
                  Do Both
                </label>
              </div>
            </div>
            <div className="h-5">
              {(rolePreferred === 'both' || rolePreferred === 'interviewer') && <InterviewerAvailabilityCTA />}
            </div>
          </fieldset>
        </div>
      </div>
      <div className="mt-2 sm:col-span-3">
        <label htmlFor="yearsOfExperience" className="block text-sm/6 font-medium text-gray-900">
          Years of Experience
        </label>
        <div className="grid grid-cols-1">
          <select
            id="years-of-experience"
            name="yearsOfExperience"
            autoComplete="years-of-experience"
            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
            {...register('types.yearsExperience', { required: 'years of experience is required.' })}
          >
            <option value="-1">Student</option>
            <option value="0">0 (or not a software engineer)</option>
            {[...Array(19)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
            <option value="20">20+</option>
          </select>
        </div>
      </div>
      <div className="mt-2 sm:col-span-3">
        <label htmlFor="company">Search and Select a Company:</label>
        <Controller
          name="company"
          control={control}
          render={({ field }) => (
            <CreatableSelect
              {...field}
              required
              options={searchResults || []}
              // isLoading={isLoading}
              onInputChange={(value) => setSearchTerm(value)}
              placeholder="Search by company name or type to add..."
              isClearable
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.discoveryId || option.name}
              onCreateOption={(inputValue) => {
                const newOption = { name: inputValue, discoveryId: '' };
                field.onChange(newOption);
              }}
            />
          )}
        />
      </div>
      <div className="mt-2 space-y-1">
        <div className="flex gap-3">
          <div className="flex h-6 shrink-0 items-center">
            <div className="size-4 group grid grid-cols-1">
              <input
                id="avoid-match"
                name="avoidMatch"
                type="checkbox"
                aria-describedby="cb-avoid-match"
                className="forced-colors:appearance-auto col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100"
                {...register('avoidMatch')}
              />
              <svg
                fill="none"
                viewBox="0 0 14 14"
                className="size-3.5 group-has-[:disabled]:stroke-gray-950/25 pointer-events-none col-start-1 row-start-1 self-center justify-self-center stroke-white"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="group-has-[:checked]:opacity-100 opacity-0"
                />
                <path
                  d="M3 7H11"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="group-has-[:indeterminate]:opacity-100 opacity-0"
                />
              </svg>
            </div>
          </div>
          <div className="text-sm/6">
            <label htmlFor="cb-avoid-match" className="font-medium text-gray-900">
              Don't interview with people I work with
            </label>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex h-6 shrink-0 items-center">
            <div className="size-4 group grid grid-cols-1">
              <input
                id="hiring-manager"
                name="company.isHiringManager"
                type="checkbox"
                aria-describedby="cb-is-hiring-manager"
                className="forced-colors:appearance-auto col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100"
                {...register('isHiringManager')}
              />
              <svg
                fill="none"
                viewBox="0 0 14 14"
                className="size-3.5 group-has-[:disabled]:stroke-gray-950/25 pointer-events-none col-start-1 row-start-1 self-center justify-self-center stroke-white"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="group-has-[:checked]:opacity-100 opacity-0"
                />
                <path
                  d="M3 7H11"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="group-has-[:indeterminate]:opacity-100 opacity-0"
                />
              </svg>
            </div>
          </div>
          <div className="text-sm/6">
            <label htmlFor="cb-is-hiring-manager" className="font-medium text-gray-900">
              I am a hiring manager
            </label>
          </div>
        </div>
      </div>
      <SubmitButton
        isLoading={updateUserPreferencesMutation.isLoading}
        defaultText="Update User Preferences"
        loadingText="loading..."
      />
      <div className="min-h-6 py-1">
        {serverErrors &&
          serverErrors.map((error, index) => (
            <ul key={index} className="text-sm text-red-500">
              <li>{error.msg}</li>
            </ul>
          ))}
        {errors.firstName && <p className="text-sm text-red-500">{errors.firstName.message}</p>}
        {errors.lastName && <p className="text-sm text-red-500">{errors.lastName.message}</p>}
        {errors.company?.name && <p className="text-sm text-red-500">{errors.company.name.message}</p>}
        {errors.company?.discoveryId && <p className="text-sm text-red-500">{errors.company.discoveryId.message}</p>}
        {errors.avoidMatch && <p className="text-sm text-red-500">{errors.avoidMatch.message}</p>}
        {errors.isHiringManager && <p className="text-sm text-red-500">{errors.isHiringManager.message}</p>}
        {errors.types?.yearsExperience && (
          <p className="text-sm text-red-500">{errors.types.yearsExperience.message}</p>
        )}
        {errors.rolePreferred && <p className="text-sm text-red-500">{errors.rolePreferred.message}</p>}
      </div>
    </form>
  );
};

export default UserPreferences;
