/* eslint-disable no-console */
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Round } from '../../types/RoundTypes';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
import { useMutation } from '@tanstack/react-query';
import axios from '../../utils/axios';
import { useToasts } from '../ToastContext';

type MakeClaimableConfirmModalProps = {
  round: Round;
  handleAction?: (_id: string) => void;
};

type setRoundClaimableMutationProps = {
  _id: string;
  claimableWhileAssigned: boolean;
};

type RoundClaimableResponse = {
  config: object;
  data: {
    _id: string;
    error?: string;
  };
  headers: object;
  request: object;
};

const MakeClaimableConfirmModal = NiceModal.create(({ round, handleAction }: MakeClaimableConfirmModalProps) => {
  const modal = useModal();
  const { dispatch } = useToasts();
  const setRoundClaimableMutation = useMutation(
    ({ _id, claimableWhileAssigned }: setRoundClaimableMutationProps): Promise<RoundClaimableResponse> =>
      axios.put('/api/rounds/' + _id + '/claimableWhileAssigned', { claimableWhileAssigned }),
    {
      onSuccess: (response: RoundClaimableResponse) => {
        handleAction(response.data._id);
      },
      onError: (response: RoundClaimableResponse) => {
        if (response.data.error) {
          dispatch({
            type: 'addToast',
            toastContent: {
              primaryMessage: 'Error',
              secondaryMessage: response.data.error,
              displayTimeout: 3000,
            },
          });
        } else {
          window.Rollbar.error(response);
          dispatch({
            type: 'addToast',
            toastContent: {
              primaryMessage: 'Error',
              secondaryMessage: 'Your interview could not be made claimable by others.',
              displayTimeout: 3000,
            },
          });
        }
      },
    }
  );

  const performAction = () => {
    setRoundClaimableMutation.mutate({ _id: round._id, claimableWhileAssigned: true });
    modal.hide();
  };

  const cancelAction = () => {
    modal.hide();
  };

  const afterTransitionLeave = () => {
    modal.remove();
  };

  const title = 'Make claimable';
  const message =
    'By making an interview claimable, you can send a share link to another interviewer to take it from you. Note, this does not cancel the interview, so please remember to cancel the interview if you can’t make it and can’t find a replacement.';

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={afterTransitionLeave}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-5 text-left sm:mt-6">{message}</div>
                  </div>
                </div>
                <div className="mt-5 flex flex-row gap-2 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                    onClick={cancelAction}
                    tabIndex={0}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                    onClick={performAction}
                    tabIndex={0}
                  >
                    Confirm
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
export default MakeClaimableConfirmModal;
