import { createContext, Dispatch, ReactElement, SetStateAction, useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { TUser } from './types/User';
import { PartialDeep } from './types/utils';
import axios from './utils/axios';

type UserUpdates = PartialDeep<TUser>;
type StateVarsUpdate = { key: string; val: unknown };

const UserContext = createContext<{
  user: TUser;
  setUser: Dispatch<SetStateAction<TUser>>;
  invalidateUser: () => Promise<void>;
  updatePrefs: (updates: UserUpdates) => Promise<{ data: TUser }>;
  updateStateVars: (updates: StateVarsUpdate) => Promise<{ data: TUser }>;
  generatePseudonym: () => Promise<{ data: TUser }>;
  updateYOE: (YOE: number | string) => Promise<{ data: TUser }>;
}>(null);

type ProviderProps = {
  children: ReactElement | ReactElement[];
};

function UserProvider({ children }: ProviderProps) {
  const [user, setUser] = useState<TUser>(null);
  window.setUser = setUser;

  const invalidateUser = (): Promise<void> => {
    // @ts-ignore
    const AngularUserService = window.angular?.element(document.body).injector().get('UserResource');
    if (AngularUserService) {
      AngularUserService.get();
    } else {
      window.Rollbar.warn('AngularUserService not available');
    }
    return;
  };

  const updateAngularReactUser = (updatedUser: PartialDeep<TUser>) => {
    // @ts-ignore
    const AngularIdentityProvider = window.angular?.element(document.body).injector().get('Identity');

    if (AngularIdentityProvider) {
      AngularIdentityProvider.updateUser(updatedUser);
    } else {
      window.Rollbar.warn('AngularIdentityProvider not available');
      setUser(Object.assign({}, user, updatedUser));
    }
  };

  const prefsMutation = useMutation(
    (prefs: UserUpdates): Promise<{ data: TUser }> => axios.put('api/users/me/prefs', prefs),
    {
      onSuccess: (response) => updateAngularReactUser(response.data),
    }
  );

  const stateVarsMutation = useMutation(
    (stateVars: StateVarsUpdate): Promise<{ data: TUser }> => axios.put('api/users/me/set-state-var', stateVars),
    {
      onSuccess: (response) => updateAngularReactUser(response.data),
    }
  );

  const pseudonymMutation = useMutation((): Promise<{ data: TUser }> => axios.put('api/users/me/pseudonym'), {
    onSuccess: (response) => updateAngularReactUser(response.data),
  });

  const yoeMutation = useMutation(
    (YOE: string | number): Promise<{ data: TUser }> => axios.put('api/users/me/update-yoe', { YOE }),
    {
      onSuccess: (response) => updateAngularReactUser(response.data),
    }
  );

  function updatePrefs(updates: UserUpdates) {
    return prefsMutation.mutateAsync(updates);
  }

  function updateStateVars(stateVar: StateVarsUpdate) {
    return stateVarsMutation.mutateAsync(stateVar);
  }

  function generatePseudonym() {
    return pseudonymMutation.mutateAsync();
  }

  function updateYOE(YOE: number | string) {
    return yoeMutation.mutateAsync(YOE);
  }

  return (
    <UserContext.Provider
      value={{ user, setUser, invalidateUser, updatePrefs, updateStateVars, generatePseudonym, updateYOE }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUser };
