type RedirectStatusCode = '404' | '500';

export const useNotFoundRedirect = (): ((statusCode?: RedirectStatusCode) => void) => {
  const handleRedirect = (statusCode: RedirectStatusCode = '500') => {
    switch (statusCode) {
      case '404':
        window.location.href = '/not-found.html';
        break;
      default:
        window.location.href = '/unexpected-error.html';
    }
  };

  return handleRedirect;
};
