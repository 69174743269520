import { useEffect, useState } from 'react';
import { useUser } from '../../userContext';

export const useCanViewBeyondCTCIBook = () => {
  const { user } = useUser();
  const [isUserAllowed, setIsUserAllowed] = useState<boolean>(false);

  useEffect(() => {
    setIsUserAllowed(true);
  }, [user]);

  return isUserAllowed;
};
