import { Disclosure } from '@headlessui/react';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import TableOfContentsChapterPanel from './TableOfContentsChapterPanel';
import TableOfContentsChapterTrigger from './TableOfContentsChapterTrigger';

interface ITableOfContentsPartPanelProps {
  bookPart: TBeyondCTCIBookPart;
}

export const ChapterHasContent = (bookChapter: TBeyondCTCIBookChapter) =>
  bookChapter.attributes.chapterContent.length > 0;

const TableOfContentsPartPanel = ({ bookPart }: ITableOfContentsPartPanelProps) => {
  const { currentChapterId } = useBCTCIContext();

  return (
    <ul>
      {bookPart.attributes.book_chapters.data.map((bookChapter: TBeyondCTCIBookChapter, chapterIndex: number) => (
        <li key={chapterIndex} id={`table-of-contents-chapter-${currentChapterId}`}>
          <Disclosure defaultOpen={bookChapter.id === currentChapterId}>
            {({ open: openL2 }) => (
              <div className="flex flex-col">
                <Disclosure.Button className={`flex flex-row ${!ChapterHasContent(bookChapter) ? 'disabled' : ''}`}>
                  <TableOfContentsChapterTrigger bookChapter={bookChapter} bookPart={bookPart} open={openL2} />
                </Disclosure.Button>

                <Disclosure.Panel className="pb-2">
                  <TableOfContentsChapterPanel bookChapter={bookChapter} bookPart={bookPart} />
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </li>
      ))}
    </ul>
  );
};

export default TableOfContentsPartPanel;
