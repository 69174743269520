import NiceModal from '@ebay/nice-modal-react';
import { AddToCalendarModal } from '../../Modals/AddToCalendarModal';
import { CalendarIcon } from '@heroicons/react/solid';
import { Round } from '../../../types/RoundTypes';
import { StringHelper } from '../../../utils/StringHelper';

const getRoundCTA = (round: Round): string => {
  if (round._companyRoundUI) {
    if (round._roleAllowed === 'interviewee') {
      return round._companyName + ' is interviewing you';
    } else {
      return 'You are interviewing ' + round._partnerName + ' for ' + round._companyName;
    }
  } else {
    return round._roleAllowed === 'interviewee'
      ? round._partnerName + ' is interviewing you'
      : 'You are interviewing ' + round._partnerName;
  }
};

const createQueryString = (params: Record<string, string>): string => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value);
  }

  return searchParams.toString();
};

export const getCalendarLinks = (round: Round): string[] => {
  if (!round) {
    return;
  }
  const params: Record<string, string> = {};
  params.location = window?.CONFIG.baseURL;
  // for iCalendar
  params.start = new Date(round.start).toISOString();
  params.end = new Date(round.end).toISOString();
  // for Google calendar
  params.dates =
    StringHelper.formatDateToCustomString(new Date(round.start), 'yyyyMMddTHHmmss') +
    '/' +
    StringHelper.formatDateToCustomString(new Date(round.end), 'yyyyMMddTHHmmss');
  params.text = getRoundCTA(round) + ' on interviewing.io';
  const roleString = round._positionTitleWithLocations
    ? 'You are interviewing for the role of ' + round._positionTitleWithLocations + '. '
    : '';
  params.details =
    roleString +
    'This is a scheduled interview with a real person, so please show up on time. If you are unable to make it, you can reschedule at ' +
    window?.CONFIG.baseUrl +
    '/schedule/view-schedule';
  const paramsString = createQueryString(params);
  const googleLink = 'https://www.google.com/calendar/event?action=TEMPLATE&' + paramsString;
  const appleLink = '/api/rounds/' + round._id + '/event.ics?' + paramsString;
  return [googleLink, appleLink];
};

const handleOpenInNewTab = (link: string) => {
  window.open(link, '_blank');
};

export const showAddToCalendarModal = (
  hasCalendarSync: boolean,
  googleLink: string | null,
  appleLink: string | null
) => {
  if (!hasCalendarSync) {
    NiceModal.show(AddToCalendarModal, {
      actionLabel: 'Google Calendar',
      handleAction: () => handleOpenInNewTab(googleLink),
      handleSecondAction() {
        handleOpenInNewTab(appleLink);
      },
      secondActionLabel: 'Apple iCalendar (iCal)',
      title: `Add to Calendar`,
      icon: (
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-50">
          <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
      ),
      message: (
        <p>
          Add this interview directly to your calendar (your calendar won't update if the interview is
          canceled/rescheduled).
        </p>
      ),
    });
  }
};
