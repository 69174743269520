import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Round } from '../../types/RoundTypes';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ShameWarning from '../ShameWarning/ShameWarning';
import RoundDate from '../RoundDate/RoundDate';
import { OrganizationType } from '../Scheduler/Types';

type cancelMessageProps = {
  round: Round;
  org: OrganizationType;
};
const CancelMessage = ({ round, org }: cancelMessageProps) => {
  const getReadableCompanyName = (org: OrganizationType) =>
    org && org.companyName ? org.companyName.replace('Professional Interviewer', 'a professional interviewer') : null;

  const getDisplayPartnerName = (round: Round, org: OrganizationType) =>
    round._roleAllowed === 'interviewee'
      ? round._companyName
        ? getReadableCompanyName(org)
        : round._partnerName
      : round._partnerName;

  const displayPartnerName = getDisplayPartnerName(round, org);

  return (
    <div className="text-left">
      Are you sure you want to cancel your{' '}
      {round._positionTitleWithLocations && <strong>{round._positionTitleWithLocations}</strong>}
      {!round._isPeerToPeerPractice && (
        <span>
          interview with <strong>{displayPartnerName}</strong> on <RoundDate roundStart={round.start} />
        </span>
      )}
      {round._isPeerToPeerPractice && (
        <span>
          interview with <strong>a peer interviewer</strong> on <RoundDate roundStart={round.start} />
        </span>
      )}
    </div>
  );
};

type RoundCancelConfirmModalProps = {
  round: Round;
  handleAction?: () => void;
  afterClose?: () => void;
  org: OrganizationType;
};
const RoundCancelConfirmModal = NiceModal.create(
  ({ round, org, handleAction, afterClose }: RoundCancelConfirmModalProps) => {
    const modal = useModal();

    const ROUND_CANCEL_SHAMING_THRESHOLD = 1000 * 3600 * 24;
    const showShameWarning = new Date(round.start).getTime() < Date.now() + ROUND_CANCEL_SHAMING_THRESHOLD;
    const isPendingRound = !!round._isPending;

    const performAction = () => {
      handleAction?.();
      modal.hide();
    };

    const afterTransitionLeave = () => {
      modal.remove();
      afterClose?.();
    };

    const rejectAction = () => {
      modal.hide();
    };

    /**
     * TODO: Add the company round cancel modal, which has form elements to submit.
     * see companyroundcancelform-component git branch.
     */
    return (
      <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
        <Dialog as="div" className="relative z-10" onClose={afterTransitionLeave}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Cancel interview
                      </Dialog.Title>
                      <div className="mt-5 sm:mt-6">
                        <CancelMessage round={round} org={org} />
                      </div>
                      {showShameWarning && !isPendingRound && <ShameWarning round={round} />}
                    </div>
                  </div>
                  <div className="flex flex-row justify-evenly">
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                        onClick={() => rejectAction()}
                        tabIndex={1}
                      >
                        Never mind
                      </button>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                        onClick={() => performAction()}
                        tabIndex={0}
                      >
                        Cancel Interview
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
);

export default RoundCancelConfirmModal;
