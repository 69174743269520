import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export type TModalProps = {
  title: string;
  handleAction?: () => void;
  afterClose: () => void;
  message: JSX.Element | string;
  rejectActionLabel: string;
  confirmActionLabel: string;
};

export const LeaveWarningModal = NiceModal.create((props: TModalProps) => {
  const modal = useModal();
  const { title, handleAction, afterClose, message, confirmActionLabel, rejectActionLabel } = props;

  const performAction = () => {
    handleAction?.();
    modal.hide();
  };

  const afterTransitionLeave = () => {
    modal.remove();
    afterClose?.();
  };

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={afterTransitionLeave}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-[#30343a] p-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6 lg:my-0 lg:max-w-xl lg:p-4">
                <div>
                  <div className="sm:mt-5 lg:mr-2 lg:mt-0">
                    <Dialog.Title as="h3" className="text-2xl text-white">
                      {title}
                    </Dialog.Title>
                    <div className="mt-5 text-sm text-white sm:mt-6 lg:mr-4">{message}</div>
                  </div>
                </div>
                <div className="mt-5 flex justify-end gap-2 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border-2 border-solid border-gray-600 bg-[#30343a] px-4 py-2 text-base font-normal text-gray-400 shadow-sm sm:text-sm lg:w-20"
                    onClick={afterTransitionLeave}
                    tabIndex={0}
                  >
                    {rejectActionLabel}
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-base font-semibold text-gray-300 shadow-sm sm:text-sm lg:w-20"
                    onClick={performAction}
                    tabIndex={1}
                  >
                    {confirmActionLabel}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
