import SettingsTabPanel from '../../components/SettingsTabPanel/SettingsTabPanel';
import SettingsAccount from '../../components/SettingsAccount/SettingsAccount';
import NotificationPreferences from '../../components/NotificationPreferences/NotificationPreferences';
import IntervieweeProfile from '../../components/IntervieweeProfile/IntervieweeProfile';
import PaymentInformation from '../../components/PaymentInformation/PaymentInformation';

export type SettingsPageProps = {
  currentTab: string;
};

const SettingsPage = ({ currentTab }: SettingsPageProps) => (
  <div className="mx-auto w-full max-w-[924px]">
    <div className="mx-auto mb-12 sm:mt-6 md:mb-10 md:mt-12 xl:pl-16">
      <SettingsTabPanel currentTab={currentTab} />
      {currentTab === 'account' && <SettingsAccount />}
      {currentTab === 'interviewee-profile' && <IntervieweeProfile />}
      {currentTab === 'notification-preferences' && <NotificationPreferences />}
      {currentTab === 'payment-information' && <PaymentInformation />}
    </div>
  </div>
);

export default SettingsPage;
