type PreviousInterviewsListProps = {
  previousFriendlyIds: string[];
  previousInterviewsAlignCenter?: boolean;
  previousInterviewsTextWhite?: boolean;
};

export default function PreviousInterviewsList({
  previousFriendlyIds,
  previousInterviewsAlignCenter,
  previousInterviewsTextWhite,
}: PreviousInterviewsListProps) {
  return (
    <>
      <span
        className={`mt-2 inline-block sm:flex items-center ${previousInterviewsAlignCenter ? 'justify-center' : ''}`}
      >
        See previous interviews:{' '}
      </span>
      <div className="">
        {previousFriendlyIds.map((friendlyId, index) => (
          <span key={friendlyId}>
            <a
              // style={{ color: previousInterviewsTextWhite ? 'white' : '#686767' }}
              target="_blank"
              rel="noopener noreferrer"
              href={`/feedback/${friendlyId}`}
              className={`underline ${
                previousInterviewsTextWhite ? 'hover:text-white text-gray-200' : 'hover:text-gray-500 text-gray-700'
              }`}
            >
              {friendlyId}
              {index !== previousFriendlyIds.length - 1 && ','}
            </a>{' '}
          </span>
        ))}
      </div>
    </>
  );
}
