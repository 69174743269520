type EmptyListExplainerPanelProps = {
  heading: string;
  description: string;
};
const EmptyListExplainerPanel = ({ heading, description }: EmptyListExplainerPanelProps) => (
  <div className="flex flex-col items-center justify-center p-6 text-center text-xs">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
    </svg>
    <span className="mt-2 text-base font-medium">{heading}</span>
    <span className="mt-2">{description}</span>
  </div>
);

export default EmptyListExplainerPanel;
