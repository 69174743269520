import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import useAddPaymentMethodMutation from '../../hooks/useAddPaymentMethodMutation';
import { useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../userContext';
import { useModal } from '@ebay/nice-modal-react';
import { Dispatch } from '../ToastContext';

type StripeAddPaymentMethodProps = {
  dispatch: Dispatch;
};

const StripeAddPaymentMethod = ({ dispatch }: StripeAddPaymentMethodProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const { user } = useUser();
  const modal = useModal();

  const addPaymentMethodMutation = useAddPaymentMethodMutation({
    onSuccess: () => {
      modal.remove();
      queryClient.invalidateQueries(['payment-methods', user._id]);
      dispatch({
        type: 'addToast',
        toastContent: {
          primaryMessage: 'Success!',
          secondaryMessage: 'Payment method saved successfully.',
          displayTimeout: 3000,
        },
      });
    },
    onError: (error) => {
      window.Rollbar.error('Error:', error.message);
    },
  });

  const cancelModal = () => {
    modal.remove();
  };

  const handlePaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      window.Rollbar.error('CardElement is not available');
      return;
    }
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      window.Rollbar.error('Error creating payment method:', error.message);
      return;
    }

    if (paymentMethod) {
      addPaymentMethodMutation.mutate({ paymentMethodId: paymentMethod.id, cardElement: cardElement, stripe: stripe });
    }
  };

  return (
    <div>
      <CardElement />
      <div className="flex flex-row gap-2">
        <div className="flex pt-4 flex-shrink-0 basis-3/6">
          <button
            className="w-100 rounded-lg px-2 py-2 font-medium text-white shadow bg-red-500 hover:bg-red-700"
            onClick={() => cancelModal()}
          >
            Cancel
          </button>
        </div>
        <div className="flex pt-4 flex-shrink-0 basis-3/6">
          <button
            className={`w-100 rounded-lg px-2 py-2 font-medium text-white shadow ${
              addPaymentMethodMutation.isLoading ? 'cursor-not-allowed bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'
            }`}
            type="submit"
            disabled={addPaymentMethodMutation.isLoading}
            onClick={handlePaymentMethod}
          >
            {addPaymentMethodMutation.isLoading ? 'Processing...' : 'Save Payment Method'}
          </button>
        </div>
      </div>
      <div className="h-4">
        {addPaymentMethodMutation.isError && (
          <div style={{ color: 'red' }}>{(addPaymentMethodMutation.error as Error).message}</div>
        )}
      </div>
    </div>
  );
};

export default StripeAddPaymentMethod;
