import { ExternalResourcePanel } from '@interviewingio/iio-react-component-lib';
import { TExternalResourcePanelStrapiComponent } from '../../../types/ExternalResourcePanelStrapiComponent';
import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';

const ExternalResource = ({ dynamicZoneItem }: { dynamicZoneItem: TExternalResourcePanelStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.8 });

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <ExternalResourcePanel
        title={dynamicZoneItem.title}
        buttonLabel={dynamicZoneItem.openButtonLabel}
        slug={dynamicZoneItem.slug}
        heading={dynamicZoneItem.heading}
        subHeading={dynamicZoneItem.subheading}
        externalResourceUrl={dynamicZoneItem.externalResourceUrl}
      />
    </div>
  );
};

export default ExternalResource;
