import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useUser } from '../../userContext';
import axios from '../../utils/axios';
import { useToasts } from '../ToastContext';
import SubmitButton from '../SubmitButton/SubmitButton';

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
}

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

const ChangePassword = () => {
  const userId = useUser()?.user?._id;
  const [serverErrors, setServerErrors] = useState<ResponseError[]>();
  const { dispatch } = useToasts();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordForm>();

  const changePasswordMutation = useMutation(
    async (data: ChangePasswordForm) =>
      await axios.put(`/api/users/${userId}/password`, data).then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Your password has been changed.',
          },
        });
        setServerErrors([]);
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: ChangePasswordForm) => {
    changePasswordMutation.mutate(data);
  };

  return (
    <div className="items-left mt-4 flex flex-col rounded-lg bg-white">
      <h2 className="text-sm/6 font-bold text-gray-800">Change password</h2>
      <form className="w-100" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="">
          <div>
            <label htmlFor="oldPassword" className="block text-sm/6 font-medium text-gray-900">
              Current Password
            </label>
            <div className="">
              <input
                type="password"
                name="oldPassword"
                id="oldPassword"
                autoComplete="old-password"
                className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1  placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                  errors.oldPassword ? 'outline-red-500' : 'outline-gray-300'
                }`}
                {...register('oldPassword', { required: 'Current password is required' })}
              />
              <div className="my-1">
                <p className="text-sm text-red-500">{errors.oldPassword && errors.oldPassword.message}</p>
              </div>
            </div>
          </div>

          <div>
            <label htmlFor="newPassword" className="block text-sm/6 font-medium text-gray-900">
              New Password
            </label>
            <div className="">
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                autoComplete="new-password"
                className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1  placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                  errors.newPassword ? 'outline-red-500' : 'outline-gray-300'
                }`}
                {...register('newPassword', {
                  required: 'New password is required',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters.',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Password cannot be longer than 100 characters.',
                  },
                })}
              />
              <div className="my-1">
                <p className="text-sm text-red-500">{errors.newPassword && errors.newPassword.message}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex flex-col">
          <SubmitButton
            isLoading={changePasswordMutation.isLoading}
            defaultText="Change Password"
            loadingText="Changing..."
          />
          <div className="min-h-6 py-1">
            {serverErrors &&
              serverErrors.map((error, index) => (
                <ul key={index} className="text-sm text-red-500">
                  <li>{error.msg}</li>
                </ul>
              ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
