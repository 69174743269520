import { useUser } from '../../userContext';
import Icon from '../Icon/Icon';
import { TUser } from '../../types/User';
import { useMutation } from '@tanstack/react-query';
import axios from '../../utils/axios';

type ConnectedProps = {
  user: TUser;
  invalidateUser: () => Promise<void>;
};
type NotConnectedProps = {
  user: TUser;
  invalidateUser: () => Promise<void>;
};

const Connected = ({ user, invalidateUser }: ConnectedProps) => {
  const unlinkIntegrationMutation = useMutation(
    (integration: string) =>
      axios
        .post('/auth/' + integration + (integration === 'google' ? '/calendar' : '') + '/unlink')
        .then((response) => response.data),
    {
      onSuccess: () => {
        invalidateUser();
      },
      onError: (error) => {
        window.Rollbar.error(error);
      },
    }
  );
  const handleUnlink = () => {
    unlinkIntegrationMutation.mutate('google');
  };
  return (
    <div className="py-3 text-sm my-3">
      <div className="flex items-center">
        <Icon className="text-green-500 mr-2">check_circle</Icon>
        <p>
          Connected to Google Calendar for {user.integrations.google}{' '}
          <span className="text-xs">
            (
            <a className="text-blue-500 hover:text-blue-700 underline" href="#" onClick={handleUnlink}>
              Disconnect
            </a>
            )
          </span>
        </p>
      </div>
    </div>
  );
};

const NotConnected = ({ invalidateUser }: NotConnectedProps) => {
  const linkGoogleMutation = useMutation(
    async () => {
      const response = await axios.get('/auth/callbacktoken');
      const token = response.data;

      const newWindow = window.open(
        '/auth/google/calendar/link?access_token=' + token,
        '_blank',
        'width=500,height=500'
      );

      return new Promise((resolve) => {
        const timer = setInterval(() => {
          if (newWindow.closed) {
            clearInterval(timer);
            resolve('Window closed');
          }
        }, 500);
      });
    },
    {
      onSuccess: () => {
        invalidateUser();
      },
      onError: (error) => {
        window.Rollbar.error(error);
      },
    }
  );
  const handleLink = () => {
    linkGoogleMutation.mutate();
  };

  return (
    <div className="mt-2">
      <h1 className="text-base font-semibold relative mt-0 mb-0 leading-5">Connect your calendar</h1>
      <p>Please connect your Google calendar so you don’t miss any interviews.</p>
      <div>
        <button className="my-6 flex" onClick={handleLink}>
          <img src="/assets/images/settings/google.svg" />
          <span className="ml-2">Connect Google Calendar</span>
        </button>
      </div>
    </div>
  );
};

const Integrations = () => {
  const { user, invalidateUser } = useUser();
  const { google } = user.integrations;
  return google === undefined ? (
    <NotConnected user={user} invalidateUser={invalidateUser} />
  ) : (
    <Connected user={user} invalidateUser={invalidateUser} />
  );
};

export default Integrations;
