import { ProblemPanel } from '@interviewingio/iio-react-component-lib';
import { TUseProblemArtifactQuery } from '@interviewingio/iio-react-component-lib/dist/components/BeyondCTCI/ProblemSolutionAIInterviewContainer';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { useProblemArtifactQuery } from '../../../hooks/BeyondCTCIBook/useProblemArtifactQuery';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { TProblemPanelStrapiComponent } from '../../../types/ProblemPanelStrapiComponent';

const Problem = ({ dynamicZoneItem }: { dynamicZoneItem: TProblemPanelStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.5 });

  const wrappedProblemArtifactQuery: TUseProblemArtifactQuery = (params) => {
    if (params.artifactType === 'markdownProblemStatement') {
      return useQuery<string>({
        queryKey: ['BeyondCTCIProblem', params.problemId, params.artifactType],
        queryFn: () =>
          Promise.resolve({ data: { artifact: dynamicZoneItem.problemStatement } }).then(
            (response) => response.data.artifact
          ),
      });
    }
    return useProblemArtifactQuery(params);
  };

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <ProblemPanel
        title={dynamicZoneItem.title}
        slug={dynamicZoneItem.slug}
        useProblemArtifactQuery={wrappedProblemArtifactQuery}
        solutionLangs={dynamicZoneItem.solutionLangs}
        defaultSolutionVisible={!!dynamicZoneItem.defaultSolutionVisible}
      />
    </div>
  );
};
export default Problem;
