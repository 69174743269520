import LogoFullDark from '../../assets/images/logo-full-dark.svg';
import MenuOpen from '../../assets/images/navigation-icons/Sidebar/menu-open.svg';
import MenuClose from '../../assets/images/navigation-icons/Sidebar/menu-close.svg';
import { useSessionStorage } from 'usehooks-ts';

interface SidebarNavMobileTriggerProps {
  onToggleOpen: (isOpen: boolean) => void;
  isNavBarOpen: boolean;
}

function SidebarNavMobileTrigger(props: SidebarNavMobileTriggerProps) {
  const { onToggleOpen, isNavBarOpen } = props;
  const [open, setOpen] = useSessionStorage<boolean | undefined>('nav-open-state', undefined);

  const handleClick = () => {
    onToggleOpen(!open);
    setOpen(!open);
  };

  const environmentLabel = window?.CONFIG.env ?? 'production';
  const backgroundColor =
    environmentLabel !== 'production' ? `bg-environment-${window?.CONFIG.env ?? 'production'}` : 'bg-black';
  const textColor = environmentLabel !== 'production' ? 'text-black' : 'text-gray-500';

  return (
    <div className={`${backgroundColor} ${textColor} fixed w-full h-14 sm:hidden z-10`} onClick={handleClick}>
      <div className="flex items-center px-4 h-full my-auto gap-6">
        <button>
          {isNavBarOpen ? (
            <img src={MenuClose} width="18px" height="18px" />
          ) : (
            <img src={MenuOpen} width="18px" height="18px" />
          )}
        </button>
        <img height="30px" width="200px" className="-ml-1" src={LogoFullDark} alt="Interviewing.io home" />
      </div>
    </div>
  );
}

export default SidebarNavMobileTrigger;
