import NiceModal from '@ebay/nice-modal-react';
import { YesNoActionModal } from '../components/Modals/YesNoActionModal';
import axios from './axios';

export const launchInterviewFromPreferences = ({ newTab }: { newTab: boolean }) => {
  const openInterview = (url: string) => {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  };

  axios.get('api/beyond-ctci/interview-problem').then((response) => {
    if (response.data.randomInterviewProblem) {
      NiceModal.show(YesNoActionModal, {
        title: 'No matching problem found',
        handleAction: () => openInterview(response.data.interviewUrl),
        message: "Sorry, but we didn't have a problem that fit the settings you picked.",
        afterClose: () => {},
        rejectActionLabel: 'Cancel',
        confirmActionLabel: 'Practice a random problem',
      });
    } else {
      openInterview(response.data.interviewUrl);
    }
  });
};
