import { FONT_FAMILY, H2 } from '@interviewingio/iio-react-component-lib';
import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

interface ITableOfContentsChapterTriggerProps {
  bookChapter: TBeyondCTCIBookChapter;
  bookPart: TBeyondCTCIBookPart;
  open: boolean;
}

const TableOfContentsChapterTrigger = ({ open, bookChapter, bookPart }: ITableOfContentsChapterTriggerProps) => (
  <>
    <H2
      margin="mb-2"
      size="text-xl"
      color={open ? `text-black` : `text-bctci-gray-text-default`}
      fontFamily={FONT_FAMILY.HEADING}
    >
      {!bookChapter.attributes.chapterContent?.length && (
        <span className="font-medium text-gray-500">{bookChapter.attributes.Name}</span>
      )}
      {bookChapter.attributes.chapterContent?.length > 0 && (
        <Link className="font-medium" to={`/beyond-ctci/${bookPart.attributes.Slug}/${bookChapter.attributes.Slug}`}>
          {bookChapter.attributes.Name}
        </Link>
      )}
    </H2>
    <ChevronDownIcon className={`ml-auto mt-2 h-6 w-6 text-structure-gray-600 shrink-0 ${open ? 'rotate-180' : ''}`} />
  </>
);

export default TableOfContentsChapterTrigger;
