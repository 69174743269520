import { FONT_FAMILY, H2 } from '@interviewingio/iio-react-component-lib';

const ContactPanel = () => (
  <div className="bg-bctci-gray-sidebar-dark mx-4 px-4 py-2">
    <H2 margin="mb-2" color="text-black" fontFamily={FONT_FAMILY.HEADING}>
      Find a mistake?
    </H2>
    <p>
      If something doesn’t look right, please{' '}
      <a href="https://iiosurveys.typeform.com/to/Nqp1kUPB" target="_blank">
        fill out this form
      </a>
      .
    </p>
  </div>
);

export default ContactPanel;
