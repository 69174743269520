import { useIntersectionObserver } from 'usehooks-ts';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { TRichTextStrapiComponent } from '../../../types/RichTextPanelStrapiComponent';
import { RichTextPanel } from '@interviewingio/iio-react-component-lib';
import { useEffect } from 'react';

const RichText = ({ dynamicZoneItem }: { dynamicZoneItem: TRichTextStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.8 });

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <RichTextPanel title={dynamicZoneItem.title} slug={dynamicZoneItem.slug} content={dynamicZoneItem.content} />
    </div>
  );
};

export default RichText;
