import { useForm, useFieldArray } from 'react-hook-form';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import axios from '../../../utils/axios';

import {
  TopicPreference,
  TopicDifficultyTier,
  BookPreferences,
  TierFormParams,
} from '../../../types/BeyondCTCIAIInterviewSettings';

import { ToggleDifficulty, ToggleTier, ToggleAll } from './Toggles';
import { useFetchManifest } from '../../../hooks/BeyondCTCIBook/useFetchManifest';
import { useFetchBeyondCTCIPreferences } from '../../../hooks/BeyondCTCIBook/useFetchBeyondCTCIPreferences';
import { launchInterviewFromPreferences } from '../../../utils/LaunchInterviewFromPreferences';

const TierForm = ({ tier, tierIndex, control, setValue }: TierFormParams) => {
  // TODO consider reset instead of setValue for performance
  const { fields: topics } = useFieldArray({
    control,
    name: `tiers.${tierIndex}.topics`,
  });

  return (
    <div className="mb-5">
      <div
        key={tier.id}
        className="mb-4 md:mb-1 flex flex-col lg:flex-row max-w-2xl justify-between py-2 border-b border-gray-100"
      >
        <div className="text-[28px] font-medium">{tier.title} </div>
        <div className="flex items-center justify-between sm:justify-around gap-1">
          <ToggleTier
            tierIndex={tierIndex}
            topicCount={topics.length}
            control={control}
            difficulty={'easy'}
            setValue={setValue}
          />
          <ToggleTier
            tierIndex={tierIndex}
            topicCount={topics.length}
            control={control}
            difficulty={'medium'}
            setValue={setValue}
          />
          <ToggleTier
            tierIndex={tierIndex}
            topicCount={topics.length}
            control={control}
            difficulty={'hard'}
            setValue={setValue}
          />
        </div>
      </div>
      <div>
        {topics &&
          topics.map((topic, topicIndex) => (
            <div key={topic.id} className="flex flex-col lg:flex-row  justify-between py-2 border-b border-gray-100">
              <div className="text-[13px] font-sans">{(topic as unknown as TopicPreference).name}</div>
              <div className="flex items-center justify-between sm:justify-around gap-1">
                <ToggleDifficulty
                  name={`tiers.${tierIndex}.topics.${topicIndex}.easy`}
                  control={control}
                  difficulty="easy"
                />
                <ToggleDifficulty
                  name={`tiers.${tierIndex}.topics.${topicIndex}.medium`}
                  control={control}
                  difficulty="medium"
                />
                <ToggleDifficulty
                  name={`tiers.${tierIndex}.topics.${topicIndex}.hard`}
                  control={control}
                  difficulty="hard"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const BookPreferencesForm = ({ bookPreferenceDefaults }: { bookPreferenceDefaults: BookPreferences }) => {
  const { register, handleSubmit, control, setValue } = useForm({ defaultValues: bookPreferenceDefaults });
  const { fields: tiers } = useFieldArray({ control, name: 'tiers' });

  const savePrefsAndLaunchInterview = (data: BookPreferences) => {
    axios
      .post('api/beyond-ctci-preferences/', data)
      .catch((e) => {
        window.Rollbar.error('Error saving bctci-preferences', e);
      })
      .finally(() => launchInterviewFromPreferences({ newTab: false }));
  };

  return (
    <form onSubmit={handleSubmit(savePrefsAndLaunchInterview)}>
      <div className="mb-0 mt-8 text-[32px] font-medium"> Problem Selection </div>
      <legend className="mb-2 text-gray-500 font-sans">
        We'll randomly select from the following set of problems:
      </legend>
      <div className="mb-8 mt-4 space-y-2 font-sans">
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="book"
            {...register('source')}
            className="text-blue-500 focus:ring focus:ring-blue-300"
          />
          Problems presented in the book
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="non-book"
            {...register('source')}
            className="text-blue-500 focus:ring focus:ring-blue-300"
          />
          Problems not presented in the book
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="both"
            {...register('source')}
            className="text-blue-500 focus:ring focus:ring-blue-300"
          />
          Both
        </label>
      </div>
      <div className="mb-5">
        <div className="max-w-2xl-between">
          <div className="text-[32px] font-medium">Topics</div>
        </div>
        <div>
          <div className="flex flex-col lg:flex-row justify-between mb-4 md:mb-1 py-2 border-b border-gray-100">
            <div className="text-[13px] font-sans text-gray-500">Set difficulty for all topics</div>
            <div className="flex items-center justify-between sm:justify-around gap-1">
              <ToggleAll control={control} difficulty="easy" setValue={setValue} />
              <ToggleAll control={control} difficulty="medium" setValue={setValue} />
              <ToggleAll control={control} difficulty="hard" setValue={setValue} />
            </div>
          </div>
        </div>
      </div>

      {tiers &&
        tiers.map((tier, tierIndex) => (
          <TierForm
            key={tier.id}
            tier={tier}
            tierIndex={tierIndex}
            control={control}
            register={register}
            setValue={setValue}
          />
        ))}
      <div className="flex max-[299px]:flex-col min-[300px]:flex-row justify-between items-center mb-16 gap-2">
        <button className="border-[1px] border-black px-2.5 py-[7px] bg-white hover:bg-gray-50 transition-colors duration-200">
          <div className="flex items-center">
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            <a href="beyond-ctci/">Book</a>
          </div>
        </button>

        {
          <div className="">
            <button
              type="submit"
              className="text-grey-600 bg-amber-300 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-amber-400 font-sans"
            >
              Start your AI Interview
            </button>
          </div>
        }
      </div>
    </form>
  );
};

const BeyondCTCIBookAIInterviewLaunchPage = () => {
  const { data: manifest } = useFetchManifest();
  const { data: preferences } = useFetchBeyondCTCIPreferences();

  const hasSavedSomeTopicPreferences = !!preferences?.tiers;
  let bookPreferenceDefaults: BookPreferences = null;

  if (manifest && preferences) {
    const tiers = {} as Record<string, TopicDifficultyTier>;
    for (const topic of Object.values(manifest.topics)) {
      if (!tiers[topic.tier]) {
        tiers[topic.tier] = { title: manifest.tiers[topic.tier].label, tierSlug: topic.tier, topics: [] };
      }
      const preference_topic = preferences.tiers
        ?.find((tier: TopicDifficultyTier) => tier.tierSlug === topic.tier)
        ?.topics.find((pref_t: TopicPreference) => pref_t.topicSlug === topic.slug);
      if (preference_topic) {
        tiers[topic.tier].topics.push(preference_topic);
      } else {
        tiers[topic.tier].topics.push({
          name: topic.label,
          topicSlug: topic.slug,
          easy: !hasSavedSomeTopicPreferences,
          medium: false,
          hard: false,
        });
      }
    }
    bookPreferenceDefaults = { source: 'book', tiers: Object.values(tiers) };
  }

  return (
    <div className="font-heading">
      <div className="text-color-black flex flex-col gap-8 bg-yellow-500 px-6 py-6 md:px-24 md:py-8 lg:px-48 lg:py-12 xl:px-80">
        <div className="max-w-5xl flex flex-col gap-1">
          <div className="text-[40px] font-medium"> Settings </div>
          <div className="text-base">
            Set the types of problems, topics, and difficulty levels you’d like to AI Interviewer to ask you.
          </div>
        </div>
      </div>
      <div className="px-6 md:px-24 md:max-w-3xl lg:px-48 lg:max-w-5xl mx-auto">
        {bookPreferenceDefaults?.tiers && <BookPreferencesForm bookPreferenceDefaults={bookPreferenceDefaults} />}
      </div>
    </div>
  );
};

export default BeyondCTCIBookAIInterviewLaunchPage;
