import { ProblemSetPanel } from '@interviewingio/iio-react-component-lib';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { useProblemArtifactQuery } from '../../../hooks/BeyondCTCIBook/useProblemArtifactQuery';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { TProblemSetPanelStrapiComponent } from '../../../types/ProblemSetPanelStrapiComponent';

const Problem = ({ dynamicZoneItem }: { dynamicZoneItem: TProblemSetPanelStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.1 });

  const wrappedProblemArtifactQuery = (params: { artifactType: string; problemId: string; language?: string }) => {
    if (params.artifactType === 'markdownProblemStatement') {
      const problem = dynamicZoneItem.problems.find((p) => p.slug === params.problemId);
      if (problem?.problemStatement) {
        return useQuery<string>({
          queryKey: ['BeyondCTCIProblem', params.problemId, params.artifactType],
          queryFn: () =>
            Promise.resolve({ data: { artifact: problem.problemStatement } }).then(
              (response) => response.data.artifact
            ),
        });
      }
    }
    return useProblemArtifactQuery(params);
  };

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <ProblemSetPanel
        title={dynamicZoneItem.title}
        slug={dynamicZoneItem.slug}
        problems={dynamicZoneItem.problems}
        useProblemArtifactQuery={wrappedProblemArtifactQuery}
      />
    </div>
  );
};

export default Problem;
