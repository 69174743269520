import { useWatch, Control } from 'react-hook-form';

import BooleanTextToggle from '../../../elements/BooleanTextToggle';

import { Difficulty, BookPreferences } from '../../../types/BeyondCTCIAIInterviewSettings';

const difficultyStyling = {
  easy: {
    label: 'Easy',
    bgColor: 'bg-easyProblem-light',
    allTierBgColor: 'bg-easyProblem-dark',
    textColor: 'text-easyProblem',
  },
  medium: {
    label: 'Medium',
    bgColor: 'bg-mediumProblem-light',
    allTierBgColor: 'bg-mediumProblem-dark',
    textColor: 'text-mediumProblem',
  },
  hard: {
    label: 'Hard',
    bgColor: 'bg-hardProblem-light',
    allTierBgColor: 'bg-hardProblem-dark',
    textColor: 'text-hardProblem',
  },
};

export const ToggleDifficulty = ({
  name,
  control,
  difficulty,
}: {
  control: any;
  name: string;
  difficulty: Difficulty;
}) => {
  const { label, bgColor, textColor } = difficultyStyling[difficulty];
  return <BooleanTextToggle label={label} control={control} name={name} bgColor={bgColor} textColor={textColor} />;
};

export const ToggleTier = ({
  difficulty,
  setValue,
  tierIndex,
  topicCount,
  control,
}: {
  difficulty: Difficulty;
  setValue: (name: string, value: unknown) => void;
  tierIndex: number;
  topicCount: number;
  control: Control<BookPreferences>;
}) => {
  const topics = useWatch({ name: `tiers.${tierIndex}.topics`, control });
  const anyToggled = topics.some((topic: any) => !!topic[difficulty]);
  const allToggled = topics.every((topic: any) => !!topic[difficulty]);

  const { label, bgColor, textColor } = difficultyStyling[difficulty];
  const backgroundColor = anyToggled ? bgColor : 'bg-gray-100';
  const color = anyToggled ? textColor : 'black';

  const setAll = () => {
    for (let i = 0; i < topicCount; i++) {
      setValue(`tiers.${tierIndex}.topics.${i}.${difficulty}`, !anyToggled);
    }
  };

  return (
    <span onClick={setAll} className={`cursor-pointer px-3 py-1 ${backgroundColor} ${color} text-base text-center`}>
      {label}
    </span>
  );
};

export const ToggleAll = ({
  difficulty,
  setValue,
  control,
}: {
  difficulty: Difficulty;
  setValue: (name: string, value: unknown) => void;
  control: Control<BookPreferences>;
}) => {
  const tiers = useWatch({ name: 'tiers', control });
  const anyToggled = tiers.some((tier) => tier.topics.some((topic: any) => !!topic[difficulty]));
  const allToggled = tiers.every((tier) => tier.topics.every((topic: any) => !!topic[difficulty]));

  const { label, allTierBgColor } = difficultyStyling[difficulty];
  const backgroundColor = anyToggled ? allTierBgColor : 'bg-gray-100';
  const color = anyToggled ? 'white' : 'black';

  const setAll = () => {
    for (let i = 0; i < tiers.length; i++) {
      for (let j = 0; j < tiers[i].topics.length; j++) {
        setValue(`tiers.${i}.topics.${j}.${difficulty}`, !anyToggled);
      }
    }
  };

  return (
    <span onClick={setAll} className={`cursor-pointer px-3 py-1 ${backgroundColor} ${color} text-base text-center`}>
      {label}
    </span>
  );
};
