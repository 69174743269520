import { useUser } from '../../userContext';

const UnusedGrantsCountMessage = () => {
  const { user } = useUser();
  const unusedGrantsCount = Object.values(user._practiceData).reduce((acc, value) => acc + value, 0);

  if (unusedGrantsCount >= 1) {
    return (
      <div className="px-2 py-3 text-center">
        You have {unusedGrantsCount} unbooked interview{unusedGrantsCount === 1 ? '' : 's'} on your account. Would you
        like to{' '}
        <a className="text-blue-500 underline" href="/dashboard/interviewee?tab=book-existing">
          {' '}
          schedule {unusedGrantsCount === 1 ? 'it' : 'them'}
        </a>
        ?
      </div>
    );
  }
};
export default UnusedGrantsCountMessage;
