import { useUser } from '../../userContext';

type BeforeYouInterviewPanelProps = {
  hasBeenInterviewee: boolean;
};
const BeforeYouInterviewPanel = ({ hasBeenInterviewee }: BeforeYouInterviewPanelProps) => {
  const { user } = useUser();

  if (hasBeenInterviewee) return null;

  return (
    <div className="my-4 p-4 flex flex-col bg-gray-50 justify-start items-start min-h-[6rem]">
      <h1 className="text-[hsl(210,12%,12%)] text-lg font-semibold mb-3 mr-auto">Before you interview</h1>
      <div className="grid lg:grid-cols-3 gap-2 w-full grid-cols-1">
        {!user.isRestricted && (
          <a
            className="shadow hover:shadow-lg max-w-2xl bg-right-bottom bg-no-repeat bg-[size:30%] md:bg-contain border-[hsl(339,61%,50%)] border-[1px] bg-white"
            // TODO: add the background image properly to this a tag: url('/assets/images/recommendations/r-showcase.svg')
            href="/showcase"
          >
            <div className=" absolute z-0"></div>
            <div className="p-4 h-40 flex flex-col justify-center relative z-10 max-w-xs">
              <div className="text-lg font-bold leading-tight">See it in action</div>
              <span className="text-sm text-gray-700 block font-medium leading-relaxed mt-1">
                Watch practice interviews that have been shared by the community
              </span>
            </div>
          </a>
        )}
        <a
          className="shadow hover:shadow-lg max-w-2xl bg-right-bottom bg-no-repeat bg-[size:30%] md:bg-contain border-[hsl(267,49%,43%)] border-[1px] bg-white"
          // TODO: add the background image properly to this a tag: url('/assets/images/recommendations/r-sandbox.svg')
          href="/interview/playground?tutorial=true"
        >
          <div className="bg-white absolute z-0 "></div>
          <div className="p-4 h-40 flex flex-col justify-center relative z-10 max-w-xs">
            <div className="text-lg font-bold leading-tight">Try it out</div>
            <span className="text-sm text-gray-700 block font-medium leading-relaxed mt-1">
              Take the interview environment<span className="block">for a spin</span>
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};
export default BeforeYouInterviewPanel;
