import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../utils/axios';
import { StripeCardElement, Stripe } from '@stripe/stripe-js';

interface AddPaymentMethodParams {
  paymentMethodId: string;
  cardElement?: StripeCardElement;
  stripe: Stripe;
}

const useAddPaymentMethodMutation = (
  options?: UseMutationOptions<unknown, Error, AddPaymentMethodParams, string>,
) => useMutation(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (paymentParams: AddPaymentMethodParams): Promise<any> => {
      const { paymentMethodId, cardElement, stripe } = paymentParams;
      const response = await axios.post('api/orders/addPaymentMethod/', {
        paymentMethodId,
      });
      if (!response.data.clientSecret) {
        window.Rollbar.error("no client secret")
      }
      return stripe.confirmCardSetup(response.data.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {},
        },
      });
    },
    options
  );

export default useAddPaymentMethodMutation;
