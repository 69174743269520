import { ImagePanel } from '@interviewingio/iio-react-component-lib';
import { TImagePanelStrapiComponent } from '../../../types/ImagePanelStrapiComponent';
import { resolveMediaUrl } from '../../../utils/strapi';
import { useIntersectionObserver } from 'usehooks-ts';
import { useEffect } from 'react';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';

const Image = ({ dynamicZoneItem }: { dynamicZoneItem: TImagePanelStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.8 });

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <ImagePanel
        title={dynamicZoneItem.title}
        slug={dynamicZoneItem.slug}
        caption={dynamicZoneItem.caption}
        imageUrl={resolveMediaUrl(dynamicZoneItem.imageMedia.data.attributes.url)}
        imageHeight={`${dynamicZoneItem.imageMedia.data.attributes.height}`}
        imageWidth={`${dynamicZoneItem.imageMedia.data.attributes.width}`}
        imageAltText={dynamicZoneItem.imageMedia.data.attributes.alternativeText}
      />
    </div>
  );
};

export default Image;
