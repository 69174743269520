import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeAddPaymentMethod from '../StripeAddPaymentMethod/StripeAddPaymentMethod';
import { Dispatch } from '../ToastContext';

type AddCardModalProps = {
  dispatch: Dispatch;
};
const AddCardModal = NiceModal.create(({ dispatch }: AddCardModalProps) => {
  const modal = useModal();

  const publishableKey = window?.CONFIG.stripe.publishableKey;
  const stripePromise = loadStripe(publishableKey);

  const afterTransitionLeave = () => {
    modal.remove();
  };

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={afterTransitionLeave}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Add Payment Method
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-4">
                  <Elements stripe={stripePromise}>
                    <StripeAddPaymentMethod dispatch={dispatch} />
                  </Elements>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export { AddCardModal };
