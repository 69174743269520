import { DedicatedCoachingCalloutCardPropsType } from './Types';

const DedicatedCoachingCalloutCard = (props: DedicatedCoachingCalloutCardPropsType) => (
  <div className="flex flex-col lg:flex-row flex-wrap pb-4">
    <div className="mr-[0.5625rem] flex-[1_1_65%]">
      <div className="mx-0 leading-relaxed text-[22px] font-medium">
        Practice with senior engineers. Get feedback. Get better.
      </div>
      <div className="mt-2 text-[16.5px] text-gray-800 font-normal leading-relaxed">
        Our professional interviewers are well-calibrated, senior engineers who’ll give you the feedback you need to get
        comfortable with technical interviews. Our interviewers have 11 years of experience on average (with a minimum
        of 4), and every interviewer has conducted at least 20 technical interviews on behalf of a FAANG or
        FAANG-adjacent company.
        <br />
        <br />
        Get {props.discount}% off when you buy 4 or more sessions.
      </div>
    </div>
    <div className="flex-[1_1_20%] bg-customLightBlue rounded-lg mt-4 lg:mt-0">
      <div className="my-[4%] ml-[4%] mr-[5%] lg:my-[6%] lg:ml-[9%] lg:mr-[10%] text-[16px]">
        <div className="text-base font-medium">Need help preparing for Google, Facebook, or Amazon?</div>
        <div className="text-base mt-1">
          Our <a href="dashboard/interviewee/dedicated-coaching/">dedicated coaching packages</a> are the single best
          thing you can do to secure an offer.
        </div>
      </div>
    </div>
  </div>
);

export default DedicatedCoachingCalloutCard;
