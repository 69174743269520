import clsx from 'clsx';

const alertTypes = {
  WHITE: 'white',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  GRAY: 'gray',
} as const;

type AlertType = typeof alertTypes[keyof typeof alertTypes];

type BadgeProps = {
  pro?: boolean;
  alertType: AlertType;
  fixed?: boolean;
  children?: React.ReactNode;
} & React.ComponentProps<'div'>;

export default function IconPill({ pro, alertType, fixed, children, className }: BadgeProps) {
  const classes = clsx(
    `_badge-${alertType || 'default'}`,
    fixed && 'w-[52px]',
    'justify-center rounded-lg py-0.5',
    className
  );
  return (
    <div className="flex flex-col lg:flex-row">
      <div className={classes}>
        {pro ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
          </svg>
        )}
        <span className="px-1 text-[9px] font-bold leading-none">{children}</span>
      </div>
    </div>
  );
}
