/**
 * Ported from the Angular util string_helper.js
 */
const isDate = (value: unknown): value is Date => value instanceof Date && !isNaN(+value);

export class StringHelper {
  /**
   * Format seconds into a countdown string
   * verbose makes the countdown of the form 1 hour 15 minutes 36 seconds instead of 1:15:36
   * minimal displays the minimal information (2 hours vs. 2 hours 15 minutes 12 seconds) and (36 vs. 00:36)
   */
  static formatCountdown(seconds: number, verbose: boolean, minimal: boolean): string {
    let outString = '';
    let started = false;
    const spacer = verbose ? ' ' : ':';
    seconds = Math.round(seconds);

    if (seconds < 0) {
      outString += '-';
      seconds = -seconds;
    }

    //add hours
    const showHours = verbose && minimal ? seconds > 3600 : seconds >= 3600;
    if (showHours) {
      const hours = verbose && minimal ? Math.ceil(seconds / 3600) : Math.floor(seconds / 3600);
      outString = verbose ? this.addPlural(hours, 'hour') : hours.toString();
      seconds = seconds % 3600;
      started = true;
    }

    //add minutes
    let showMinutes;
    if (verbose) {
      showMinutes = minimal ? !started && seconds > 60 : seconds >= 60;
    } else {
      showMinutes = started || !minimal || seconds >= 60;
    }

    // = verbose ? (started || (minimal ? (seconds > 60): (seconds >= 60))) : (started || !minimal || (seconds >= 60));
    if (showMinutes) {
      const minutes = verbose && minimal ? Math.ceil(seconds / 60) : Math.floor(seconds / 60);
      if (started) {
        outString += spacer;
      }
      outString += verbose
        ? this.addPlural(minutes, 'minute')
        : String(minutes).padStart(started || !minimal ? 2 : 0, '0');
      seconds = seconds % 60;
      started = true;
    }

    //add seconds
    const showSeconds = !verbose || !started || (!minimal && seconds > 0);
    if (showSeconds) {
      if (started) {
        outString += spacer;
      }
      outString += verbose
        ? this.addPlural(seconds, 'second')
        : String(seconds).padStart(started || !minimal ? 2 : 0, '0');
    }

    return outString;
  }

  static addPlural(val: number, subject: string, pluralString?: string) {
    if (!pluralString) pluralString = subject + 's';
    return val.toString() + ' ' + (val === 1 ? subject : pluralString);
  }

  static formatDateToCustomString(date: Date, format: string): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    if (!isDate(date)) {
      date = new Date(date);
    }

    const replacements: Record<string, string> = {
      YYYY: date.getFullYear().toString(),
      YY: date.getFullYear().toString().slice(-2),
      MM: (date.getMonth() + 1).toString().padStart(2, '0'),
      M: (date.getMonth() + 1).toString(),
      DD: date.getDate().toString().padStart(2, '0'),
      D: date.getDate().toString(),
      HH: date.getHours().toString().padStart(2, '0'),
      H: date.getHours().toString(),
      mm: date.getMinutes().toString().padStart(2, '0'),
      m: date.getMinutes().toString(),
      ss: date.getSeconds().toString().padStart(2, '0'),
      s: date.getSeconds().toString(),
      EEEE: daysOfWeek[date.getDay()],
      EEE: daysOfWeek[date.getDay()].slice(0, 3),
      MMMM: months[date.getMonth()],
      MMM: months[date.getMonth()].slice(0, 3),
    };

    // Replace placeholders in the format string
    return format.replace(/YYYY|YY|MMMM|MMM|MM|M|DD|D|HH|H|mm|m|ss|s|EEEE|EEE/g, (match) => replacements[match]);
  }
}
