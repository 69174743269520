import { BrowserRouter, Route, Routes } from 'react-router';
import { useCanViewBeyondCTCIBook } from '../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { BeyondCTCIBookChapterPageRoute } from '../pages/BeyondCTCIBook/BeyondCTCIBookChapterPage/BeyondCTCIBookChapterPage';
import BeyondCTCIBookAIInterviewLaunchPage from '../pages/BeyondCTCIBook/BeyondCTCIBookAIInterviewLaunchPage/BeyondCTCIBookAIInterviewLaunchPage';
import { SolutionPageRoute } from '../pages/BeyondCTCIBook/BeyondCTCIBookSolutionPage/BeyondCTCIBookSolutionPage';
import { useReactInNgRootScopeUpdate } from '../hooks/useReactInNgRootScopeUpdate';

const ReactInNgRootScopeUpdater = (): null => {
  useReactInNgRootScopeUpdate();
  return null;
};

const BeyondCTCIBookArea = () => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  if (!isUserAllowed) return null;

  return (
    <BrowserRouter>
      <ReactInNgRootScopeUpdater />
      <Routes>
        <Route path="beyond-ctci">
          <Route path=":partSlug/:chapterSlug" element={<BeyondCTCIBookChapterPageRoute />} />
          <Route path="interview-ai" element={<BeyondCTCIBookAIInterviewLaunchPage />} />
          <Route path="solution/:problemSlug" element={<SolutionPageRoute />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default BeyondCTCIBookArea;
