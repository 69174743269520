import { Tooltip } from 'react-tooltip';
import { Round } from '../../types/RoundTypes';
import CompanyIconLogo from '../CompanyIconLogo/CompanyIconLogo';
import RoundFocus from '../RoundFocus/RoundFocus';
import RoundPartnerDescription from '../RoundPartnerDescription/RoundPartnerDescription';

type InterviewerNonPendingRoundInfoProps = {
  round: Round;
  hasMetBefore: boolean;
  isPremiumPracticeInterview: boolean;
  roundFocusDescription: string;
  interviewFocusText: string;
  showClaimable: boolean;
};

const InterviewerNonPendingRoundInfo = ({
  round,
  hasMetBefore,
  isPremiumPracticeInterview,
  roundFocusDescription,
  interviewFocusText,
  showClaimable,
}: InterviewerNonPendingRoundInfoProps) => {
  const intervieweeYOE = round?._intervieweeYOE;
  const careerGroup =
    round?._partnerCareerGroup === window?.CONFIG.const.careerGroups.newGrad ? 'student' : round?._partnerCareerGroup;
  let yoeTooltip = '';
  if (careerGroup === window?.CONFIG.const.careerGroups.unknown) {
    yoeTooltip = `We don’t know your partner’s years of experience.`;
  } else {
    yoeTooltip = `Your partner is a/an ${careerGroup} engineer with ${Math.max(
      intervieweeYOE,
      0
    )} years of experience.`;
  }

  const IntervieweeDetails = () => (
    <span
      className="underline"
      data-tooltip-id="interviewee-yoe"
      data-tooltip-content={yoeTooltip}
      data-tooltip-place="bottom"
    >
      {round._partnerName}
      <Tooltip id="interviewee-yoe" opacity={1} style={{ backgroundColor: '#000' }} />
    </span>
  );

  return (
    <>
      {`${hasMetBefore ? 'You are rematching' : 'You are interviewing'} with `}
      <IntervieweeDetails />
      {` for `}
      {round.details.companyIconUrl && isPremiumPracticeInterview && (
        <>
          <RoundPartnerDescription round={round} tooltipId="logoPartnerDescription" tooltipPlace="bottom">
            <CompanyIconLogo round={round} />
          </RoundPartnerDescription>
          {' / '}
        </>
      )}
      <RoundFocus roundDetailsFocus={round.details.focus} roundFocusDescription={roundFocusDescription}>
        {interviewFocusText}
      </RoundFocus>
      {showClaimable && round.claimableWhileAssigned && (
        <span>
          (
          <a href={`/claim/${round._id}`} className="text-blue-500 hover:underline">
            claimable
          </a>
          )
        </span>
      )}
    </>
  );
};

export default InterviewerNonPendingRoundInfo;
