import React, { ComponentType, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';

import InterviewersSuperAdminTab from './SuperAdminTabs/Interviewers/InterviewersSuperAdminTab';
import RewardLinksSuperAdminTab from './SuperAdminTabs/RewardLinksSuperAdminTab';
import DCCoursesSuperAdminTab from './SuperAdminTabs/DCCourses/DCCoursesSuperAdminTab';
import CouponAndFreeSessionBanners from './CouponAndFreeSessionBanners/CouponAndFreeSessionBanners';
import InterviewAIPage from '../pages/InterviewAI/InterviewAI';
import SalaryNegotiationPage from '../pages/SalaryNegotiation/SalaryNegotiationPage';
import DedicatedCoachingCalloutCard from './Scheduler/DedicatedCoachingCalloutCard';
import DedicatedCoachingPage from '../pages/DedicatedCoaching/DedicatedCoachingPage';
import CompanyIntroductionsPage from '../pages/CompanyIntroductionsPage/CompanyIntroductionsPage';
import ClaimInterviewList from '../pages/claims/ClaimInterviewList';
import ClaimInterview from '../pages/claims/ClaimInterview';
import SessionGrantsSuperAdminPage from '../pages/super-admin/session-grants/SessionGrants';
import ParticipantIntroductionPanel from './ParticipantIntroductionPanel/ParticipantIntroductionPanel';
import RoundInformationCard from './RoundInformationCard/RoundInformationCard';
import BeyondCTCIBookAIInterviewLaunchPage from '../pages/BeyondCTCIBook/BeyondCTCIBookAIInterviewLaunchPage/BeyondCTCIBookAIInterviewLaunchPage';
import BeyondCTCIBookChapterPage from '../pages/BeyondCTCIBook/BeyondCTCIBookChapterPage/BeyondCTCIBookChapterPage';
import SolutionPage from '../pages/BeyondCTCIBook/BeyondCTCIBookSolutionPage/BeyondCTCIBookSolutionPage';
import RoundCTA from './RoundCTA/RoundCTA';
import ChangePassword from './ChangePassword/ChangePassword';
import BeyondCTCIBookArea from '../islands/BeyondCTCIBookArea';
import RestrictedOrgIntervieweeBoard from './RestrictedOrgIntervieweeBoard/RestrictedOrgIntervieweeBoard';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import SettingsAccount from './SettingsAccount/SettingsAccount';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import BeforeYouInterviewPanel from './IntervieweePerformanceDashboard/IntervieweePerformanceDashboard';
import ForgotPasswordWrapper from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import UpcomingPage from '../pages/UpcomingPage/UpcomingPage';
import BookSignupModal from './Modals/BookSignupModal';
import SidebarNav from './SidebarNav/SidebarNav';
import SidebarNavMobileTrigger from './SidebarNav/SidebarNavMobileTrigger';
import HeaderNav from './HeaderNav/HeaderNav';

const ComponentRegistry: Record<string, ComponentType> = {
  InterviewersSuperAdminTab,
  SessionGrantsAdminTab: SessionGrantsSuperAdminPage,
  RewardLinksSuperAdminTab,
  DCCoursesSuperAdminTab,
  CouponAndFreeSessionBanners,
  ClaimInterview,
  ClaimInterviewList,
  CompanyIntroductionsPage,
  DedicatedCoachingCalloutCard,
  DedicatedCoachingPage,
  SalaryNegotiationPage,
  InterviewAIPage,
  BeyondCTCIBookAIInterviewLaunchPage,
  ParticipantIntroductionPanel,
  RoundInformationCard,
  BeyondCTCIBookChapterPage,
  BeyondCTCISolutionPage: SolutionPage,
  RoundCTA,
  ChangePassword,
  BeyondCTCIBookArea,
  RestrictedOrgIntervieweeBoard,
  ChangeEmail,
  SettingsAccount,
  SettingsPage,
  BeforeYouInterviewPanel,
  ForgotPasswordWrapper,
  ResetPassword,
  UpcomingPage,
  BookSignupModal,
  SidebarNav,
  SidebarNavMobileTrigger,
  HeaderNav,
};

export default function ReactInNgPortalManager() {
  const [portals, setPortals] = useState<
    Record<string, { element: JSX.Element; componentId: string; container: HTMLElement }>
  >({});

  useEffect(() => {
    window.reactInNgPortalManager = {
      addPortal: function <P>(key: string, componentId: string, container: HTMLElement, props: P): JSX.Element {
        const component: ComponentType | undefined = ComponentRegistry[componentId];
        if (!component) {
          window.Rollbar.error(`React component ${componentId} not in the registry!`);
          return;
        }
        window.Rollbar.debug(`Adding react portal to the registry: ${key} (${componentId})`);
        const portal: JSX.Element = React.createElement(component, props);

        setPortals((prevPortals) => ({
          ...prevPortals,
          [key]: { element: ReactDOM.createPortal(portal, container), componentId, container },
        }));
      },
      updatePortal: function <P>(key: string, componentId: string, props: P): void {
        const portalEntry = portals[key];
        if (!portalEntry) {
          window.Rollbar.error(`Attempted to update a portal that doesn't exist in the registry: ${key}`);
          return;
        }
        window.Rollbar.debug(`Updating react portal: ${key}`);
        const { container } = portalEntry;

        if (componentId) {
          const component = ComponentRegistry[componentId];
          const updatedPortal = React.createElement(component, props);

          setPortals((prevPortals) => ({
            ...prevPortals,
            [key]: { element: ReactDOM.createPortal(updatedPortal, container), componentId, container },
          }));
        } else {
          window.Rollbar.warn(`Component for portal key ${key} could not be found in the registry.`);
        }
      },
      removePortal: function (key: string) {
        if (!portals[key]) {
          window.Rollbar.error(`Attempted to remove a portal that doesn't exist in the registry: ${key}`);
          return;
        }
        window.Rollbar.debug(`Removing react portal: ${key} (${portals[key].componentId})`);
        setPortals((prevPortals) => {
          const { [key]: _portalToRemove, ...remainingPortals } = prevPortals;
          return remainingPortals;
        });
      },
    };
  }, [portals]);

  return <>{Object.values(portals).map((portalEntry) => portalEntry.element)}</>;
}
