import { createContext, ReactElement, useContext, useState, useEffect } from 'react';

const WarnBeforeLeavingContext = createContext<{ setBrowserWarningEnabled: (enabled: boolean) => void }>(null);

type ProviderProps = {
  children: ReactElement | ReactElement[];
};

function WarnBeforeLeavingProvider({ children }: ProviderProps) {
  const [browserWarningEnabled, setBrowserWarningEnabled] = useState<boolean>(false);

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (browserWarningEnabled) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [browserWarningEnabled]);

  return (
    <WarnBeforeLeavingContext.Provider value={{ setBrowserWarningEnabled }}>
      {children}
    </WarnBeforeLeavingContext.Provider>
  );
}

function useWarnBeforeLeaving() {
  const context = useContext(WarnBeforeLeavingContext);
  if (!context) {
    throw new Error('useWarnBeforeLeaving must be used within a WarnBeforeLeavingProvider');
  }
  return context;
}

export { WarnBeforeLeavingProvider, useWarnBeforeLeaving };
