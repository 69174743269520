import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

const useInvalidateBookingCache = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleBookingEvent = () => {
      queryClient.invalidateQueries({ queryKey: ['upcoming-sessions'] });
      queryClient.invalidateQueries({ queryKey: ['next-round'] });
      queryClient.invalidateQueries({ queryKey: ['active-interview'] });
    };

    window.addEventListener('bookingEvent', handleBookingEvent);

    return () => {
      window.removeEventListener('bookingEvent', handleBookingEvent);
    };
  }, [queryClient]);
};

export default useInvalidateBookingCache;
