import { Round } from '../../types/RoundTypes';

type ScheduledStartProps = {
  round: Round;
};

export default function ScheduledStart({ round }: ScheduledStartProps) {
  return (
    <div className="lg:border-solid text-center font-medium lg:border-r lg:pr-10 flex flex-col lg:flex-row">
      <div className="">
        {new Date(round.start).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}{' '}
        <span className="nowrap">
          {new Date(round.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
        </span>
      </div>
    </div>
  );
}
