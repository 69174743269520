import Icon from '../../Icon/Icon';

type UpcomingCTAProps = React.ComponentProps<'a'>;

export default function UpcomingCTA({ style }: UpcomingCTAProps) {
  return (
    <a
      href="/schedule/view-schedule"
      style={style}
      className="relative block w-full rounded border-t-[1px] border-[hsla(0,0%,100%,0.2)] bg-[hsla(0,0%,100%,0.1)] py-1"
    >
      <div className="hover-white link flex h-[1.6rem] items-center justify-center text-center text-[11px] font-semibold uppercase leading-none tracking-[0.05px] text-[hsla(0,0%,100%,0.9)]">
        <div>See all upcoming interviews</div>
        <Icon>play_arrow</Icon>
      </div>
    </a>
  );
}
