function HeaderNav() {
  return (
    <div ng-if="::!hideHeader" className="_fixed-header" ng-class="{'fixed': !absolute, 'absolute': absolute}">
      <div className="ph-16px pl0-ns _row w-100 justify-between relative h-100">
        <a ng-href="{{homePath}}" className="db dn-ns">
          {/* <logo class="white" medium="true"></logo> */}
        </a>
        <div className="_header-left">
          <div className="_row flex-auto ph2">
            <div className="dn flex-ns">
              <div ng-if="::pageTitle" className="dn flex-row-ns flex-nowrap-ns items-center-ns relative">
                <a
                  className="_breadcrumb nowrap"
                  ng-class="::{'pointer-none': !pageTitleLink}"
                  ng-href="{{::pageTitleLink}}"
                >
                  <span ng-class="{'black': !pageTitleLink}">{/* {{::pageTitle}} */}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="_row flex-auto justify-end z-999999 pr2 pr4-ns h-100 gap-2">
          <button
            ng-if="inIntervieweeDash"
            ng-click="openDiscordInvite()"
            style={{ backgroundColor: 'rgb(84, 93, 217)' }}
            className="text-white br4 pv1 ph2"
          >
            <div className="dn db-ns">Join our Discord Community!</div>
            <div className="db dn-ns">Join our Discord</div>
          </button>

          <div className="dn db-ns static-ns oc-gray-6">
            <div className="flex">
              {/* <icon source="public" class="w-100 w-auto-ns o-90 f-11px self-center"></icon> */}
              <span className="fw7 f-xs ph1">
                <span className="inline-flex" data-title="{{::gmtOffset}}" data-placement="bottom" bs-tooltip>
                  {/* {{::timezone}} */}
                </span>
              </span>
            </div>
          </div>

          <div className="db dn-ns absolute-ns o-70 text-white">
            <div className="flex">
              {/* <icon source="public" class="w-100 w-auto-ns o-90 f-11px self-center"></icon> */}
              <span className="fw7 f-xs ph1">
                <span className="inline-flex">{/* {{::timezone}} */}</span>
              </span>
            </div>
          </div>

          <a className="_row justify-center h-100 oc-gray-6 hover-black link" ng-href="/faq">
            {/* <icon class="_nav-icon" source="help"></icon> */}
          </a>

          <div className="dropdown notifications border-box _row justify-center h-100">
            <a
              className="h-100 flex justify-center dropdown-toggle items-center oc-gray-6 hover-black link"
              data-toggle="dropdown"
            >
              <div className="flex relative">
                {/* <icon class="_nav-icon" source="notifications"></icon> */}
                <div className="circle-new absolute top-0 right-0" ng-if="hasNew"></div>
              </div>
            </a>
            <ul className="dropdown-menu">
              <li className="seen-all" ng-if="hasNew">
                <a ng-click="seenAll(); $event.stopPropagation()">Mark all as read</a>
              </li>
              <li
                ng-repeat="notification in notifications | orderBy:'createdTime':true | limitTo:limit"
                className="notification-container"
                ng-click="notificationClick(notification)"
              >
                <div className="circle circle-red new" ng-if="notification.isNew"></div>
                <div ng-bind-html="::notification.message | trustedHtml"></div>
                <div className="fw5 i">
                  {/* <icon data-source="event" class="v-mid mr1"></icon> */}
                  {/* {{::notification.createdTime | fromNow }} */}
                </div>
              </li>
              <li className="pv3" ng-if="notifications.length===0">
                <div className="blue center tc mb1">{/* <icon class="f-icon" source="inbox"></icon> */}</div>
                <div className="ph2 tc">
                  <p className="f7">
                    <span className="dib gray">You don't have any new notifications.</span>
                  </p>
                </div>
              </li>
              <li className="mv0 tc pv1 bt b--black-05">
                <a className="blue f7 fw5" ng-href="/notifications">
                  Show All
                </a>
              </li>
            </ul>
          </div>
          <div
            ng-mouseenter="accountMenuHovered()"
            ng-mouseleave="closeAccountMenu()"
            className="pointer oc-gray-6 hover-black link h-100"
          >
            <div className="_nav-icon">
              {/* <icon source="account_circle"></icon>
          <icon class="f-icon-sm absolute right-0 nr2 o-70" data-source="arrow_drop_down"></icon> */}
            </div>
            <div
              ng-if="accountMenuVisible"
              className="flex w-100 measure-narrow bg-white shadow-4 br3 absolute right-0 top-0 mt7 cursor-default velocity-enter-transition-slideDownIn z-max"
              data-velocity-opts="{ display: 'flex', duration: 120 }"
            >
              <div className="w-100">
                <div className="db ph3 pv-12px">
                  <div className="ttu tracked-mega lightest-gray fw7">{/* {{me.pseudonym}} */}</div>
                  <div className="mw-100 lightest-gray truncate">
                    <small>{/* ({{me.email}}) */}</small>
                  </div>
                  <div className="db dn-ns mt2" ng-if="isSuperAdmin">
                    <button className="w-100">
                      {/* <icon source="verified_user"></icon> */}
                      <a href="/admin">Super Admin</a>
                    </button>
                  </div>
                </div>
                <a
                  ng-if="adminOrgIds.length > 0 && !hasMultipleOrgs"
                  ng-href="/org/{{ adminOrgIds[0] }}"
                  className="_account-box-link"
                >
                  <span className="f7">Admin</span>
                </a>
                <a
                  ng-if="adminOrgIds.length > 0 && hasMultipleOrgs"
                  ng-click="toggleOrgMenu()"
                  className="_account-box-link relative"
                >
                  <span className="f7">Admin</span>
                  {/* <icon ng-if="!multipleOrgMenuExpanded" data-source="keyboard_arrow_right" class="f4 ml-auto"></icon>
              <icon ng-if="multipleOrgMenuExpanded" data-source="keyboard_arrow_down" class="f4 ml-auto"></icon> */}
                </a>
                <div className="w-auto" ng-if="hasMultipleOrgs && multipleOrgMenuExpanded">
                  <a
                    className="db f7 lh-solid w-100 pv2 pl4 black bt b--near-white hover-bg-black-025"
                    ng-repeat="orgId in adminOrgIds"
                    ng-href="/org/{{ orgId }}"
                  >
                    {/* {{ (orgsById[orgId] || {}).companyName || orgId }} */}
                  </a>
                </div>
                <a className="_account-box-link" ng-href="/settings">
                  <span className="black">Settings</span>
                </a>
                <a className="_account-box-link" ng-if="::bugFormUrl" target="_blank" ng-href="{{::bugFormUrl}}">
                  <span className="black">Report a bug</span>
                  {/* <icon data-source="open_in_new" class="f7 ml-auto black-20"></icon> */}
                </a>
                <a className="_account-box-link" href="" ng-click="logout()">
                  <span className="black">Log out</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderNav;
