import { Tooltip, PlacesType } from 'react-tooltip';
import { Round } from '../../types/RoundTypes';
import { PracticeType } from '../NonPendingRound/NonPendingRound';

type RoundPartnerDescriptionProps = {
  round: Round;
  tooltipId: string;
  tooltipPlace: PlacesType;
  alternateContent?: string;
} & React.ComponentProps<'span'>;

type PartnerDescriptionConfig = {
  interviewer: string | null;
  interviewee: string | null;
};

const getPartnerDescriptionConfig = (round: Round, practiceType: PracticeType): PartnerDescriptionConfig => {
  switch (practiceType) {
    case 'branded': {
      return {
        interviewer: round._companyName, // using roundDetails.companyName here assumes that company name will be structured something like "Professional interviewer at {{companyName}}",
        interviewee: 'Your partner requested: ' + round._companyName,
      };
    }
    case 'non-branded': {
      return {
        interviewer: round._companyName,
        interviewee: null,
      };
    }
    case 'peer': {
      return {
        interviewer: 'Peer Interviewer',
        interviewee: null,
      };
    }
  }
};

const getOtherRole = (role: string) => (role === 'interviewee' ? 'interviewer' : 'interviewee');
const getPracticeType = (round: Round) =>
  !round.orgId ? 'peer' : round._isBrandedPremiumPractice ? 'branded' : 'non-branded';

const getPartnerDescription = (round: Round): string | null => {
  const practiceType = getPracticeType(round);
  const config = getPartnerDescriptionConfig(round, practiceType);
  let roundPartnerRole: null | string = null;

  roundPartnerRole = getOtherRole(round._roleAllowed);

  if (!roundPartnerRole || !config || !(roundPartnerRole in config)) {
    return null;
  }
  return config[roundPartnerRole as keyof typeof config];
};

export default function RoundPartnerDescription({
  round,
  tooltipId,
  tooltipPlace,
  className,
  children,
  alternateContent,
}: RoundPartnerDescriptionProps) {
  const isMobile = /Mobi|iPhone|Android/i.test(navigator.userAgent);
  let roundPartnerDescription = getPartnerDescription(round);
  if (!roundPartnerDescription && alternateContent) {
    roundPartnerDescription = alternateContent;
  }

  if (isMobile) {
    return <span className={className}>{children}</span>;
  }
  return (
    <span
      className={className}
      data-tooltip-id={tooltipId}
      data-tooltip-content={roundPartnerDescription}
      data-tooltip-place={tooltipPlace}
    >
      {children}
      <Tooltip id={tooltipId} opacity={1} style={{ backgroundColor: '#000' }} />
    </span>
  );
}
