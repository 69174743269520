import React from 'react';

interface SubmitButtonProps {
  isLoading: boolean;
  defaultText: string;
  loadingText: string;
  onClick?: () => void;
  className?: string;
}

const SubmitButton = ({ isLoading, defaultText, loadingText, onClick, className = '' }: SubmitButtonProps) => (
  <div className="flex justify-end pt-4">
    <button
      className={`rounded-lg px-6 py-2 font-medium text-white shadow ${
        isLoading ? 'cursor-not-allowed bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'
      } ${className}`}
      type="submit"
      disabled={isLoading}
      onClick={onClick}
    >
      {isLoading ? loadingText : defaultText}
    </button>
  </div>
);

export default SubmitButton;
