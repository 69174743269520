import { BookInfoPanel } from '@interviewingio/iio-react-component-lib';
import bookCover from '../../../assets/images/BCTCI/book-cover.webp';

const BookInfo = () => (
  <BookInfoPanel
    handleButtonClick={() => {
      window.open('https://www.amazon.com/dp/195570600X', '_blank');
    }}
    imageSrc={bookCover}
    buttonLabel="About the book"
  />
);

export default BookInfo;
