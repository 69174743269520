import { useQuery } from '@tanstack/react-query';
import { TUser } from '../../types/User';
import { useUser } from '../../userContext';
import axios from '../../utils/axios';
import { OrganizationType } from '../Scheduler/Types';
import { Round } from '../../types/RoundTypes';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import Icon from '../Icon/Icon';

type CardType = {
  org: OrganizationType;
  hasUpcomingRound: boolean;
  canScheduleRound: boolean;
  message: string;
  icon: string;
  round?: Round;
};
const BOOKING_METHOD_ORG_LIMITS = 'BookingMethodOrgLimits';

const getMyOrgIds = (user: TUser, role: string): string[] => {
  const orgIds = user.orgRoles
    .filter((org: { roles: string[] }) => org.roles.includes(role))
    .map((org: { orgId: string }) => org.orgId);
  return orgIds;
};

const getUserOrgsById = (allOrgs: OrganizationType[], userOrgIds: string[]): { [k: string]: OrganizationType } =>
  Object.fromEntries(allOrgs.filter((org) => userOrgIds.includes(org._id)).map((org) => [org._id, org]));

const RestrictedOrgIntervieweeBoard = () => {
  const { user } = useUser();

  const { isError, isLoading, data: allOrgs } = useQuery(
    ['all-orgs'],
    (): Promise<OrganizationType[]> => axios.get('/api/employer/cache/').then((response) => response.data)
  );

  const { isLoading: userRoundsLoading, data: userRounds } = useQuery(
    ['user-rounds', user?._id],
    (): Promise<Round[]> => axios.get('/api/rounds/forUser?joinableOnly=true').then((response) => response.data),
    {
      enabled: !!user?._id,
    }
  );

  let roundsByOrg: Record<string, Round[]> = {};

  if (!userRoundsLoading) {
    roundsByOrg = userRounds.reduce((acc, round) => {
      const key = round.orgId || '';
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(round);
      return acc;
    }, {} as Record<string, Round[]>);
  }

  // TODO: Render a proper "loading" card here to avoid layout shift.
  if (isLoading) {
    return null;
  }

  if (isError) {
    return <div>Something went wrong.</div>;
  }

  const orgIds = getMyOrgIds(user, 'interviewee');
  const userOrgsById = getUserOrgsById(allOrgs, orgIds);

  const cardsData: CardType[] = [];
  for (const [idkey, org] of Object.entries(userOrgsById)) {
    if (!org || org._isPremiumPractice || !(org._isStandalone || org._isSandbox)) {
      continue;
    }
    if (org._isSandbox) {
      cardsData.push({
        org,
        hasUpcomingRound: !!(roundsByOrg[idkey] && roundsByOrg[idkey].find((round) => round.start > new Date())),
        canScheduleRound: org?.accountSettings?.standalone?.sandboxBookingMethod === BOOKING_METHOD_ORG_LIMITS,
        message: `Get practice on behalf of ${org.companyName}`,
        icon: 'fitness_center',
      });
    } else if ((roundsByOrg[idkey] || []).length === 0) {
      cardsData.push({
        org,
        hasUpcomingRound: false,
        canScheduleRound: true,
        message: 'Real Interview',
        icon: 'work',
      });
    }
  }

  if (!(cardsData.length > 0)) return null;

  const handleBookInterviewClick = (cardData: CardType) => {
    // TODO: Avoid the angular call here.
    if (window?.angular?.element(document.body).injector().get('InterviewService')) {
      window.angular?.element(document.body).injector().get('InterviewService').bookOrgInterview(cardData.org);
    }
  };

  return (
    <div className="my-4 flex flex-col gap-4">
      {cardsData.map((cardData, index) => (
        <div
          className="w-full border border-[hsl(0,0%,93%)] shadow-[0px_1px_3px_rgba(0,0,0,0.04)] rounded-md"
          key={index}
        >
          <div className="w-full">
            <div className="flex flex-col md:flex-row items-center p-6 text-center md:text-left">
              <div className="w-full flex flex-col md:flex-row">
                <CompanyLogo
                  className="!bg-contain mx-auto md:mx-0"
                  company_icon_url={
                    cardData.org.companyLogoUrl ? cardData.org.companyLogoUrl : cardData.org.companyIconUrl
                  }
                  size="medium"
                />
                <div className="md:pl-3 flex flex-col justify-center">
                  <span className="text-sm font-bold leading-tight">{cardData.org.companyName}</span>
                  <div className="text-[hsl(210,12%,12%)] leading-tight mt-2 pt-1">
                    <Icon className="inline-block text-sm mr-1 align-middle">{cardData.icon}</Icon>
                    <div className="inline leading-tight">{cardData.message}</div>
                  </div>
                </div>
              </div>
              {cardData.canScheduleRound && (
                <button
                  onClick={() => handleBookInterviewClick(cardData)}
                  className="bg-blue-500 rounded-md text-white hover:bg-blue-700 mt-2 md:mt-0 ml-0 md:ml-auto cursor-pointer w-52 h-9"
                >
                  <span className="whitespace-nowrap text-sm text-[hsl(0,0%,100%)]">
                    Book{` ${cardData.hasUpcomingRound ? 'another' : ''} `}interview
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default RestrictedOrgIntervieweeBoard;
