import { DocumentDownloadPanel, DOCUMENT_TYPE_ICONS } from '@interviewingio/iio-react-component-lib';
import axios from '../../../utils/axios';
import { TDocumentDownloadPanelStrapiComponent } from '../../../types/DocumentDownloadPanelStrapiComponent';
import { TStrapiPrivateMediaObject } from '../../../types/StrapiMediaObject';
import { useEffect } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';

const createHandleDownloadButtonClick =
  (mediaId: string, documentMediaObject: TStrapiPrivateMediaObject) => async () => {
    try {
      const response = await axios.get(`/api/beyond-ctci/media?id=${mediaId}`, {
        responseType: 'blob',
      });

      const filename = documentMediaObject.name || 'downloaded_file';

      // Create a new Blob from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      window.Rollbar.error('Download failed:', error);
    }
  };

const DocumentDownload = ({ dynamicZoneItem }: { dynamicZoneItem: TDocumentDownloadPanelStrapiComponent }) => {
  const { setCurrentContentSlug } = useBCTCIContext();
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.8 });

  useEffect(() => {
    if (isIntersecting) {
      setCurrentContentSlug(dynamicZoneItem.slug);
    }
  }, [isIntersecting]);

  return (
    <div ref={ref}>
      <DocumentDownloadPanel
        documentName={dynamicZoneItem.title}
        icon={
          dynamicZoneItem.documentMedia.data.attributes.ext === '.pdf'
            ? DOCUMENT_TYPE_ICONS.PDF
            : DOCUMENT_TYPE_ICONS.OTHER
        }
        title={dynamicZoneItem.title}
        slug={dynamicZoneItem.slug}
        primaryDescription={dynamicZoneItem.primaryDescription}
        secondaryDescription={dynamicZoneItem.secondaryDescription}
        onDownloadButtonClick={createHandleDownloadButtonClick(
          dynamicZoneItem.documentMedia.data.id,
          dynamicZoneItem.documentMedia.data.attributes
        )}
      />
    </div>
  );
};

export default DocumentDownload;
