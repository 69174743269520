import axios from '../../utils/axios';
import { useQuery } from '@tanstack/react-query';
import { Round } from '../../types/RoundTypes';

export const useNextRound = () =>
  useQuery({
    queryKey: ['next-round'],
    queryFn: (): Promise<Round> =>
      axios
        .get('/api/rounds/getNext', {
          params: {},
        })
        .then((response) => response.data),
    refetchInterval: 60 * 1000,
  });
