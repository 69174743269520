import { PlacesType, Tooltip } from 'react-tooltip';
import './HintPulse.css';

type HintPulseProps = {
  show: boolean;
  tipText?: string;
  tipPlacement?: PlacesType;
};
export default function HintPulse({ show, tipText, tipPlacement }: HintPulseProps) {
  return (
    <div className={`hint-pulse tooltip placement${show ? '' : ' hidden'}`}>
      <div className="hint-pulse-pulse"></div>
      {tipText && (
        <>
          <div
            data-tooltip-id="hint-pulse"
            data-tooltip-content={tipText}
            data-tooltip-place={tipPlacement}
            className="hint-pulse-dot"
          ></div>
          <Tooltip id="hint-pulse" />
        </>
      )}
    </div>
  );
}
