import axios from '../../utils/axios';
import { useQuery } from '@tanstack/react-query';
import { Interview } from '../../types/RoundTypes';

export const useActiveInterview = () =>
  useQuery({
    queryKey: ['active-interview'],
    queryFn: (): Promise<Interview> =>
      axios
        .get('/api/interviews/active', {
          params: {},
        })
        .then((response) => response.data),
    refetchInterval: 60 * 1000,
  });
