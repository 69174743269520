import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from '../../utils/axios';
import { useToasts } from '../ToastContext';
import SubmitButton from '../SubmitButton/SubmitButton';

interface ChangeEmailForm {
  newEmail: string;
}

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

const ChangeEmail = () => {
  const [serverErrors, setServerErrors] = useState<ResponseError[]>();
  const { dispatch } = useToasts();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangeEmailForm>();

  const changeEmailMutation = useMutation(
    async (data: ChangeEmailForm) => await axios.put(`/api/users/me/email`, data).then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Email change has been requested, please check your email to confirm.',
          },
        });
        setServerErrors([]);
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: ChangeEmailForm) => {
    changeEmailMutation.mutate(data);
  };

  return (
    <div className="items-left mt-2 flex flex-col rounded-lg bg-white">
      <h2 className="text-sm/6 font-bold text-gray-800">Change email</h2>
      <form className="w-100" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="sm:col-span-4">
          <label htmlFor="newEmail" className="block text-sm/6 font-medium text-gray-900">
            New Email
          </label>
          <div className="">
            <input
              id="newEmail"
              name="newEmail"
              type="email"
              autoComplete="email"
              className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                errors.newEmail ? 'outline-red-500' : 'outline-gray-300'
              }`}
              {...register('newEmail', {
                required: 'New email is required.',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              })}
            />
            <div className="">
              <p className="text-sm text-red-500">{errors.newEmail && errors.newEmail.message}</p>
              {serverErrors &&
                serverErrors.map((error, index) => (
                  <p key={index} className="text-sm text-red-500">
                    {error.msg}
                  </p>
                ))}
            </div>
          </div>
        </div>

        <SubmitButton isLoading={changeEmailMutation.isLoading} defaultText="Change Email" loadingText="Changing..." />
      </form>
    </div>
  );
};

export default ChangeEmail;
