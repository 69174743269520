import { Round, ROUND_STATE } from '../types/RoundTypes';

const isDate = (value: unknown): value is Date => value instanceof Date && !isNaN(+value);

const FOCUS_STANDARD = 'standard';
const FOCUS_STANDARD_EXPERIENCED = 'standard - experienced';
const FOCUS_STANDARD_MENTORSHIP = 'standard (mentorship)';
const FOCUS_STANDARD_EXPERIENCED_MENTORSHIP = 'standard - experienced (mentorship)';
const FOCUS_SYSTEMS_DESIGN = 'systems design';
const FOCUS_SYSTEMS_DESIGN_EXPERIENCED = 'systems design - experienced';
const FOCUS_SYSTEMS_DESIGN_MENTORSHIP = 'systems design (mentorship)';
const FOCUS_SYSTEMS_DESIGN_EXPERIENCED_MENTORSHIP = 'systems design - experienced (mentorship)';
const FOCUS_MACHINE_LEARNING = 'machine learning';
const FOCUS_MACHINE_LEARNING_MENTORSHIP = 'machine learning (mentorship)';
const FOCUS_MACHINE_LEARNING_SYSTEMS_DESIGN = 'machine learning - systems design';
const FOCUS_MACHINE_LEARNING_SYSTEMS_DESIGN_MENTORSHIP = 'machine learning - systems design (mentorship)';
const FOCUS_BEHAVIORAL = 'behavioral';
const FOCUS_BEHAVIORAL_EXPERIENCED = 'behavioral - experienced';
const FOCUS_BEHAVIORAL_MENTORSHIP = 'behavioral (mentorship)';
const FOCUS_BEHAVIORAL_EXPERIENCED_MENTORSHIP = 'behavioral - experienced (mentorship)';
const FOCUS_FRONTEND = 'frontend';
const FOCUS_FRONTEND_MENTORSHIP = 'frontend (mentorship)';
const FOCUS_ENG_MANAGEMENT = 'eng management';
const FOCUS_ENG_MANAGEMENT_MENTORSHIP = 'eng management (mentorship)';
const FOCUS_PRODUCT_DESIGN = 'product design';
const FOCUS_PRODUCT_DESIGN_MENTORSHIP = 'product design (mentorship)';
const FOCUS_ANDROID_SYSTEMS_DESIGN = 'android systems design';
const FOCUS_ANDROID_SYSTEMS_DESIGN_MENTORSHIP = 'android systems design (mentorship)';
const FOCUS_DATA_ENG = 'data engineering';
const FOCUS_DATA_ENG_MENTORSHIP = 'data engineering (mentorship)';
const FOCUS_IOS = 'ios';
const FOCUS_IOS_MENTORSHIP = 'ios (mentorship)';
const FOCUS_PREP_COURSE = 'preparation course';
const FOCUS_EXPERIENCED_PREP_COURSE = 'experienced preparation course';
const FOCUS_MACHINE_LEARNING_PREP_COURSE = 'machine learning preparation course';
const FOCUS_MANAGER_PREP_COURSE = 'manager preparation course';
const FOCUS_PREP_COURSE_BRANDED = 'preparation course (branded)';
const FOCUS_EXPERIENCED_PREP_COURSE_BRANDED = 'experienced preparation course (branded)';
const FOCUS_MACHINE_LEARNING_PREP_COURSE_BRANDED = 'machine learning preparation course (branded)';
const FOCUS_MANAGER_PREP_COURSE_BRANDED = 'manager preparation course (branded)';
const FOCUS_SITE_RELIABILITY_ENGINEERING = 'site reliability engineering';
const FOCUS_SITE_RELIABILITY_ENGINEERING_MENTORSHIP = 'site reliability engineering (mentorship)';
const FOCUS_TECHNICAL_PROGRAM_MANAGEMENT = 'technical program management';
const FOCUS_TECHNICAL_PROGRAM_MANAGEMENT_MENTORSHIP = 'technical program management (mentorship)';
const FOCUS_CAREER_COACHING_MENTORSHIP = 'career coaching (mentorship)';

const ROUND_FOCUSES = {
  FOCUS_STANDARD,
  FOCUS_STANDARD_EXPERIENCED,
  FOCUS_STANDARD_MENTORSHIP,
  FOCUS_STANDARD_EXPERIENCED_MENTORSHIP,
  FOCUS_FRONTEND,
  FOCUS_FRONTEND_MENTORSHIP,
  FOCUS_SYSTEMS_DESIGN,
  FOCUS_SYSTEMS_DESIGN_EXPERIENCED,
  FOCUS_SYSTEMS_DESIGN_MENTORSHIP,
  FOCUS_SYSTEMS_DESIGN_EXPERIENCED_MENTORSHIP,
  FOCUS_CAREER_COACHING_MENTORSHIP,
  FOCUS_BEHAVIORAL,
  FOCUS_BEHAVIORAL_EXPERIENCED,
  FOCUS_BEHAVIORAL_MENTORSHIP,
  FOCUS_BEHAVIORAL_EXPERIENCED_MENTORSHIP,
  FOCUS_DATA_ENG,
  FOCUS_DATA_ENG_MENTORSHIP,
  FOCUS_MACHINE_LEARNING,
  FOCUS_MACHINE_LEARNING_MENTORSHIP,
  FOCUS_MACHINE_LEARNING_SYSTEMS_DESIGN,
  FOCUS_MACHINE_LEARNING_SYSTEMS_DESIGN_MENTORSHIP,
  FOCUS_IOS,
  FOCUS_IOS_MENTORSHIP,
  FOCUS_ANDROID_SYSTEMS_DESIGN,
  FOCUS_ANDROID_SYSTEMS_DESIGN_MENTORSHIP,
  FOCUS_ENG_MANAGEMENT,
  FOCUS_ENG_MANAGEMENT_MENTORSHIP,
  FOCUS_PRODUCT_DESIGN,
  FOCUS_PRODUCT_DESIGN_MENTORSHIP,
  FOCUS_PREP_COURSE,
  FOCUS_EXPERIENCED_PREP_COURSE,
  FOCUS_MACHINE_LEARNING_PREP_COURSE,
  FOCUS_MANAGER_PREP_COURSE,
  FOCUS_PREP_COURSE_BRANDED,
  FOCUS_EXPERIENCED_PREP_COURSE_BRANDED,
  FOCUS_MACHINE_LEARNING_PREP_COURSE_BRANDED,
  FOCUS_MANAGER_PREP_COURSE_BRANDED,
  FOCUS_SITE_RELIABILITY_ENGINEERING,
  FOCUS_SITE_RELIABILITY_ENGINEERING_MENTORSHIP,
  FOCUS_TECHNICAL_PROGRAM_MANAGEMENT,
  FOCUS_TECHNICAL_PROGRAM_MANAGEMENT_MENTORSHIP,
};

const PREP_COURSE_FOCUSES = [
  FOCUS_PREP_COURSE,
  FOCUS_EXPERIENCED_PREP_COURSE,
  FOCUS_MACHINE_LEARNING_PREP_COURSE,
  FOCUS_MANAGER_PREP_COURSE,
  FOCUS_PREP_COURSE_BRANDED,
  FOCUS_EXPERIENCED_PREP_COURSE_BRANDED,
  FOCUS_MACHINE_LEARNING_PREP_COURSE_BRANDED,
  FOCUS_MANAGER_PREP_COURSE_BRANDED,
];

const ROUND_FOCUS_VALUES = Object.values(ROUND_FOCUSES);
const ROUND_FOCUSES_INTERVIEWER_SELF_SETTABLE = ROUND_FOCUS_VALUES.filter((rf) => !PREP_COURSE_FOCUSES.includes(rf));
const SOON_PERIOD = 30 * 60 * 1000;

export class RoundHelper {
  static getRoundTimeStatus(round: Round): ROUND_STATE {
    const timeUntilUpcomingRound = Math.round((new Date(round.start).getTime() - Date.now()) / 1000);
    if (timeUntilUpcomingRound < 0) {
      return 'IS_CURRENT';
    }
    if (new Date(round.start).getTime() - Date.now() < SOON_PERIOD) {
      return 'IS_SOON';
    }
    if (this.isSameDay(new Date(), new Date(round.start))) {
      return 'IS_TODAY';
    }
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (this.isSameDay(tomorrow, round.start)) {
      return 'IS_TOMORROW';
    }
    const now = new Date();
    if (round.end < now) {
      return 'IS_FINISHED';
    } else {
      return 'IS_IN_FUTURE';
    }
  }
  static isSameDay = (date1: Date, date2: Date): boolean => {
    if (!isDate(date1)) {
      date1 = new Date(date1);
    }
    if (!isDate(date2)) {
      date2 = new Date(date2);
    }
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  static isRoundCurrent = (round: Round): boolean =>
    (new Date(round.start).getTime() - new Date().getTime()) / 1000 < 0;
  static canRoundBeCancelled = (round: Round): boolean => new Date(round.start).getTime() > new Date().getTime();
  static isRoundPractice = (round: Round): boolean => round.details.kind === 'ScheduledRound';
  static isRoundSelfSettable = (round: Round): boolean =>
    ROUND_FOCUSES_INTERVIEWER_SELF_SETTABLE.includes(round.details.focus);
  static isInterviewer = (round: Round): boolean => round._roleAllowed === 'interviewer';
  static roundOrgIdExists = (round: Round): boolean => !!round.orgId;
  static canRoundBeMadeClaimable = (round: Round): boolean =>
    this.canRoundBeCancelled(round) &&
    this.isRoundPractice(round) &&
    this.isRoundSelfSettable(round) &&
    this.isInterviewer(round) &&
    this.roundOrgIdExists(round);
  static isDedicatedCoaching = (round: Round) => PREP_COURSE_FOCUSES.includes(round.details.focus);
}
