import { useUser } from '../../userContext';
import Icon from '../Icon/Icon';
import axios from '../../utils/axios';
import { Button } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import { useToasts } from '../ToastContext';
import { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';

type IntegrationsSettingsProps = {
  onlyShowButton: boolean;
};

export default function IntegrationsSettings({ onlyShowButton }: IntegrationsSettingsProps) {
  const { user, invalidateUser } = useUser();
  const { dispatch } = useToasts();
  const modal = useModal();
  const [hasGoogleIntegration, setHasGoogleIntegration] = useState(user?.integrations?.google);

  const linkGoogleMutation = useMutation(
    async () => {
      const response = await axios.get('/auth/callbacktoken');
      const token = response.data;

      const newWindow = window.open(
        '/auth/google/calendar/link?access_token=' + token,
        '_blank',
        'width=500,height=500'
      );

      return new Promise((resolve) => {
        const timer = setInterval(() => {
          if (newWindow.closed) {
            clearInterval(timer);
            resolve('Window closed');
          }
        }, 500);
      });
    },
    {
      onSuccess: () => {
        invalidateUser();
        setHasGoogleIntegration(user?.integrations?.google);
        if (hasGoogleIntegration) {
          dispatch({
            type: 'addToast',
            toastContent: {
              primaryMessage: 'Success!',
              secondaryMessage: 'Google Calendar connected.',
              displayTimeout: 3000,
            },
          });
        }
        modal.hide();
      },
      onError: (error) => {
        window.Rollbar.error(error);
      },
    }
  );
  const linkGoogle = async () => {
    linkGoogleMutation.mutate();
  };

  return (
    <>
      {!onlyShowButton && (
        <>
          <h1 className="text-lg font-semibold">Connect your calendar</h1>
          <p className="text-sm">Please connect your Google Calendar so you don’t miss any interviews.</p>
        </>
      )}
      {hasGoogleIntegration && (
        <div ng-if="googleEmail " className="bb b--near-white pa3 f6 mv3">
          <div className="_row">
            <Icon className="green mr1 nudge-down">check_circle</Icon>
            <p>
              Connected to Google Calendar for {user?.integrations?.google}{' '}
              <span className="f9">
                (
                <a className="blue hover-dark-blue link" href="#" ng-click="unlink('google')">
                  Disconnect
                </a>
                )
              </span>
            </p>
          </div>
        </div>
      )}
      {!hasGoogleIntegration && (
        <div className="p-2 border-[1px] mt-4 border-gray-300 bg-gray-50 rounded-md text-center hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 cursor-pointer">
          <Button className="w-full items-center justify-center flex flex-row" onClick={linkGoogle}>
            <img className="logo" src="/assets/images/settings/google.svg" />
            <span className="mx-2">Connect Google Calendar</span>
          </Button>
        </div>
      )}
    </>
  );
}
