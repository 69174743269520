import { Button } from '@interviewingio/iio-react-component-lib';
import { useState } from 'react';
import { useUser } from '../../userContext';
import { TUser } from '../../types/User';
import HintPulse from './HintPulse/HintPulse';
import RoundCTA from '../RoundCTA/RoundCTA';
import UpcomingCTA from './UpcomingCTA/UpcomingCTA';
import CurrentJoinButton from './CurrentJoinButton/CurrentJoinButton';
import DateAndCountdown from './DateAndCountdown/DateAndCountdown';
import { RoundHelper } from '../../utils/RoundHelper';
import { useActiveInterview } from '../../hooks/Sessions/useActiveInterview';
import { useOrg } from '../../hooks/useOrg';
import { useNextRound } from '../../hooks/Sessions/useNextRound';
import useInvalidateBookingCache from '../../hooks/useInvalidateBookingCache';
import useInterval from '../../hooks/useInterval';
import { Tooltip } from 'react-tooltip';
import { Interview } from '../../types/RoundTypes';

const getCalendarSync = (user: TUser): boolean => !!(user.integrations.google || user.integrations.microsoft);

const getOtherUserPseudonym = (interview: Interview, user: TUser) => {
  if (user._id === interview.interviewee.user) {
    return interview.interviewer.pseudonym;
  }
  if (user._id === interview.interviewer.user) {
    return interview.interviewee.pseudonym;
  }
  throw new Error('Unexpected state');
};

export default function RoundInformationCard() {
  useInvalidateBookingCache();
  const { user } = useUser();
  const { data: activeInterview } = useActiveInterview();
  const { isLoading: roundIsLoading, data: round } = useNextRound();
  const {
    isLoading: orgIsLoading,
    isError: orgHasError,
    data: roundOrg,
  } = useOrg(round?.orgId, {
    enabled: !!round?.orgId,
  });
  const [isCurrent, setIsCurrent] = useState<boolean>(false);
  const [useCompanyLogo, setUseCompanyLogo] = useState<boolean>();
  const hasCalendarSync = getCalendarSync(user);

  const routeRegex = /^\/schedule\/.*/;
  const hideUpcomingCTA = routeRegex.test(location.pathname);

  useInterval(() => {
    if (!roundIsLoading) {
      setIsCurrent(RoundHelper.isRoundCurrent(round));

      setUseCompanyLogo(
        round.details?.kind === 'CompanyRound' &&
          !this.details.companyIsSandbox &&
          !orgIsLoading &&
          roundOrg.companyLogoUrl !== null
      );
    }
  }, 1000);

  return (
    <div>
      {activeInterview && (
        <div
          // Default to blue background if there is no roundOrg color or if we're in a loading/error state.
          className={`${orgIsLoading || orgHasError ? 'bg-blue' : ''} mb-[1.6rem] rounded-md`}
          style={{
            backgroundColor: roundOrg?.bgColor ? roundOrg.bgColor : '#1c66ba', // blue
          }}
        >
          <div
            className="bg-blue relative mb-[1.6rem] box-border flex w-full flex-col items-center justify-center self-start rounded-md py-[1.6rem]"
            style={{
              backgroundColor: activeInterview?._isBrandedPremiumPractice ? 'inherit' : activeInterview?.companyColor,
            }}
          >
            <p className="white">
              <span>
                Your interview with
                <strong> {getOtherUserPseudonym(activeInterview, user)} </strong>
                is still active.
              </span>
            </p>
            <Button
              as="a"
              href={`/interview/${activeInterview.friendlyId}`}
              className="_btn-secondary _btn-large mt-2 rounded-lg px-4 py-2"
              variant="none"
              purpose="secondary"
            >
              <span>Resume Interview</span>
              <HintPulse tipPlacement="top-end" show={true} />
            </Button>
          </div>
        </div>
      )}
      {round && !activeInterview && (
        <div
          className={`${orgIsLoading || orgHasError ? 'bg-blue' : ''} mb-[1.6rem] rounded-md`}
          style={{
            backgroundColor: roundOrg?.bgColor ? roundOrg.bgColor : '#1c66ba', // blue
          }}
        >
          <div
            className={`relative box-border flex w-full flex-col items-center justify-center self-start rounded-md ${
              hideUpcomingCTA ? 'rounded-bl rounded-br' : 'rounded-bl-none rounded-br-none'
            }`}
            style={{ backgroundColor: round?._isBrandedPremiumPractice ? 'inherit' : round?.companyColor }}
          >
            <div className="z-[1] m-[1.6rem] p-[1.6rem] text-center">
              {useCompanyLogo ? (
                <img className="center mb-2 max-h-6" src={round._companyLogoUrl} />
              ) : (
                <div className="text-center mb-2 align-middle py-1 font-medium text-base tracking-normal text-white">
                  Practice Interview
                </div>
              )}
              <div className="text-center mb-2 align-middle py-1 font-medium text-base tracking-normal text-white">
                <RoundCTA
                  round={round}
                  showCompany={false}
                  showTypeOfInterviewerBadge={true}
                  showClaimable={false}
                  previousInterviewsAlignCenter={true}
                  previousInterviewsTextWhite={true}
                />
              </div>
              <div
                data-tooltip-id={!isCurrent && !hasCalendarSync ? 'calendar-sync-tooltip' : null}
                data-tooltip-content={!isCurrent && !hasCalendarSync ? 'Add to calendar' : null}
                data-tooltip-place={!isCurrent && !hasCalendarSync ? 'top' : null}
                className={`interview-date-time my-4 text-sm white hover-white inline-flex break-normal sm:flex sm:justify-center text-[13px] font-medium ${
                  !hasCalendarSync ? 'pointer _underline-dotted' : ''
                }`}
              >
                <DateAndCountdown round={round} lowercase={false} hasCalendarSync={hasCalendarSync} />
                <br />
                <Tooltip id="calendar-sync-tooltip" />
              </div>
              <CurrentJoinButton isCurrent={isCurrent} round={round} />
            </div>
          </div>
          {!hideUpcomingCTA && (
            <UpcomingCTA
              style={{
                backgroundColor: round._isBrandedPremiumPractice ? 'inherit' : round.companyColor,
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
