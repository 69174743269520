type RoundDateProps = {
  roundStart: Date;
};
const RoundDate = ({ roundStart }: RoundDateProps) => (
  <>
    <span className="">
      {new Date(roundStart).toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}
    </span>{' '}
    <span className="">
      {new Date(roundStart).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
    </span>
  </>
);

export default RoundDate;
