import axios from '../../utils/axios';
import { TManifestProblem } from '../../types/BeyondCTCIBookManifest';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TUseProblemArtifactQuery } from '@interviewingio/iio-react-component-lib/dist/components/BeyondCTCI/ProblemSolutionAIInterviewContainer';

interface UseFetchManifestArtifactProps {
  problemId: string;
  artifactType: string;
  language?: string;
}

export type TFetchManifestArtifactQueryResult = UseQueryResult<TManifestProblem, AxiosError>;

export const useProblemArtifactQuery: TUseProblemArtifactQuery = ({
  problemId,
  artifactType,
  language,
}: UseFetchManifestArtifactProps) => {
  const queryKey = ['BeyondCTCIProblem', problemId, artifactType];
  let artifactUrl = `/api/beyond-ctci/problem/${problemId}/${artifactType}`;
  if (language) {
    queryKey.push(language);
    artifactUrl = `${artifactUrl}?language=${language}`;
  }
  return useQuery<string>({
    queryKey,
    queryFn: () => axios.get(artifactUrl).then((response) => response.data?.artifact),
  });
};
