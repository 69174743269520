import { Tooltip } from 'react-tooltip';

type RoundFocusProps = {
  roundDetailsFocus?: string;
  roundFocusDescription: string;
} & React.ComponentProps<'strong'>;

export default function RoundFocus({ roundFocusDescription, children }: RoundFocusProps) {
  const isMobile = /Mobi|iPhone|Android/i.test(navigator.userAgent);

  if (isMobile) {
    return <strong className="underline-faded inline-block cursor-default underline flex-shrink-0">{children}</strong>;
  }
  return (
    <>
      <strong
        className="underline-faded inline-block cursor-default underline flex-shrink-0"
        data-tooltip-content={roundFocusDescription}
        data-tooltip-place="bottom"
        data-tooltip-id="round-focus-description"
      >
        {children}
      </strong>
      <Tooltip id="round-focus-description" opacity={1} style={{ backgroundColor: '#000' }} />
    </>
  );
}
