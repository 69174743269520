import { useUser } from '../../userContext';
import Select from 'react-select';
import SubmitButton from '../SubmitButton/SubmitButton';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useToasts } from '../ToastContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from '../../utils/axios';
import { TUser } from '../../types/User';

const VISA_TYPES = {
  VISA_TYPE_US_CITIZEN: 'us_citizen', // US citizen, green card, etc
  VISA_TYPE_TN_1: 'tn_1', // have or need a TN-1 visa and am a citizen of Canada or Mexico
  VISA_TYPE_FRIENDLY_COUNTRY: 'friendly_country', // have or need a visa and am a citizen of Singapore, Chile, or Australia
  VISA_TYPE_H1_B_NEW: 'h1_b_new', // new H1-B
  VISA_TYPE_HB_1_TRANSFER: 'h1_b_transfer', // transfer existing H1-B
  VISA_TYPE_OPT: 'opt', // educational visa
  VISA_TYPE_OTHER: 'other', // temp state, should be changed to a known state when we get more info
};

const VISA_TYPE_DEFINITIONS = {
  [VISA_TYPES.VISA_TYPE_US_CITIZEN]:
    'I do not need a visa to work in the US (e.g. US citizen, green card holder, or permanent resident)',
  [VISA_TYPES.VISA_TYPE_TN_1]: 'I have or need a TN-1 visa and am a citizen of Canada or Mexico',
  [VISA_TYPES.VISA_TYPE_FRIENDLY_COUNTRY]: 'I have or need a visa and am a citizen of Singapore, Chile, or Australia',
  [VISA_TYPES.VISA_TYPE_H1_B_NEW]: 'I need a new H1-B visa',
  [VISA_TYPES.VISA_TYPE_HB_1_TRANSFER]: 'I have an H1-B visa and will need a transfer',
  [VISA_TYPES.VISA_TYPE_OPT]: 'I have an OPT visa (educational)',
  [VISA_TYPES.VISA_TYPE_OTHER]: 'I need or have something not listed here',
};

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

type location = {
  name: string;
  _id: string;
};

interface IntervieweeProfileForm {
  linkedin: {
    handle: string;
  };
  visa: {
    needed: string;
  };
  settings: {
    locationIds: {
      interviewee: string[];
    };
  };
}

const IntervieweeProfile = () => {
  const { user } = useUser();

  const [searchTerm, setSearchTerm] = useState('');
  const [serverErrors, setServerErrors] = useState<ResponseError[]>();
  const { dispatch } = useToasts();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IntervieweeProfileForm>({
    defaultValues: {
      linkedin: user?.linkedin,
      visa: user?.visa,
      settings: user?.settings,
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { data: locationResults, isLoading } = useQuery(['location-search', searchTerm], () =>
    axios
      .get('/api/locations', { params: searchTerm })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response) => response.data.map((item: any) => ({ name: item.name, _id: item._id })))
  );

  const updateIntervieweeProfileMutation = useMutation(
    async (data: IntervieweeProfileForm): Promise<{ data: TUser }> =>
      axios.put('/api/users/me/interviewee-profile', data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Your interviewee profile has been updated.',
          },
        });
        setServerErrors([]);
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: IntervieweeProfileForm) => {
    updateIntervieweeProfileMutation.mutate(data);
  };

  return (
    <div className="text-[14px] mt-6 border-[1px] border-gray-200 rounded-sm p-6 shadow-md">
      <div>
        <h2 className="block text-[16px] font-medium text-gray-900">Profile Information</h2>
        <p className="mt-1">
          So we can show relevant positions and companies. We'll never share your details without permission.
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mt-4 flex-col">
          <div className="sm:col-span-4">
            <label htmlFor="linkedInHandle" className="block text-[16px] font-medium text-gray-900">
              LinkedIn Handle
            </label>
            <div className="mt-2">
              <div className="flex items-center rounded-md bg-white outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
                <input
                  type="text"
                  name="handle"
                  id="handle"
                  className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  placeholder="in/williamhgates"
                  {...register('linkedin.handle', { required: 'linkedin handle required.' })}
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-3 mt-4">
            <label htmlFor="visaNeeded" className="block text-[16px] font-medium text-gray-900">
              Visa Status
            </label>
            <div className="mt-2 grid grid-cols-1">
              <select
                id="visa-needed"
                name="needed"
                autoComplete="visa-needed"
                className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                {...register('visa.needed', { required: 'visa field required.' })}
              >
                <option value="null">I'd rather not say</option>
                {Object.entries(VISA_TYPE_DEFINITIONS).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-4 w-100 flex flex-col">
            <label htmlFor="locationIds" className="block text-[16px] font-medium text-gray-900">
              I'm willing to work in:
            </label>
            <Controller
              name="settings.locationIds.interviewee"
              control={control}
              render={({ field }) => (
                <Select
                  className="mt-2"
                  isMulti
                  {...field}
                  required
                  options={locationResults || []}
                  isLoading={isLoading}
                  onInputChange={(value) => setSearchTerm(value)}
                  placeholder="Search by city name"
                  isClearable
                  getOptionLabel={(option: string | location) => {
                    if (!isLoading) {
                      if (typeof option === 'string') {
                        return locationResults.find((item: location) => item._id === option)?.name;
                      }
                      return option.name;
                    }
                  }}
                  getOptionValue={(option: string | location) => (typeof option === 'string' ? option : option._id)}
                />
              )}
            />
          </div>
        </div>
        <SubmitButton
          isLoading={updateIntervieweeProfileMutation.isLoading}
          defaultText="Update Interviewee Profile"
          loadingText="loading..."
        />
        <div className="min-h-6 py-1">
          {serverErrors &&
            serverErrors.map((error, index) => (
              <ul key={index} className="text-sm text-red-500">
                <li>{error.msg}</li>
              </ul>
            ))}
          {errors.linkedin && <p className="text-sm text-red-500">{errors.linkedin.handle.message}</p>}
          {errors.visa && <p className="text-sm text-red-500">{errors.visa.needed.message}</p>}
          {errors.settings && <p className="text-sm text-red-500">{errors.settings.locationIds.message}</p>}
        </div>
      </form>
    </div>
  );
};

export default IntervieweeProfile;
