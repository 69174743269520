import { RoundCTAProps } from '../../types/RoundTypes';
import PendingRound from '../PendingRound/PendingRound';
import NonPendingRound from '../NonPendingRound/NonPendingRound';

export default function RoundCTA({
  round,
  showCompany,
  showClaimable,
  showTypeOfInterviewerBadge,
  previousInterviewsAlignCenter,
  previousInterviewsTextWhite,
}: RoundCTAProps) {
  return (
    <>
      {round._isPending && <PendingRound companyName={round.details.companyName} />}
      {!round._isPending && (
        <NonPendingRound
          round={round}
          showCompany={showCompany}
          showClaimable={showClaimable}
          showTypeOfInterviewerBadge={showTypeOfInterviewerBadge}
          previousInterviewsAlignCenter={previousInterviewsAlignCenter}
          previousInterviewsTextWhite={previousInterviewsTextWhite}
        />
      )}
    </>
  );
}
