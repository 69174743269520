import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import IntegrationsSettings from '../IntegrationsSettings/IntegrationsSettings';

export type TModalProps = {
  title: string;
  handleAction?: () => void;
  handleSecondAction?: () => void;
  afterClose: () => void;
  icon: JSX.Element | string;
  message: JSX.Element | string;
  actionLabel: string;
  secondActionLabel: string;
};
/**
 * A Single Action Modal but with another action handler and another action label added, as well as the integration settings to connect Google Calendar.
 *
 * Features:
 *  - Title
 *  - Message
 *  - Action button label
 *  - Second action button label
 *  - A `handleAction` callback that is called when the user clicks the action button
 *    - if the action throws, the modal remains open)
 *  - A `handleSecondAction` callback
 *  - An `afterClose` callback that is called after the modal has been removed (e.g. trigger navigation or state changes)
 *
 */
const AddToCalendarModal = NiceModal.create((props: TModalProps) => {
  const modal = useModal();
  const { handleAction, handleSecondAction, afterClose, message } = props;

  const performAction = () => {
    handleAction?.();
    modal.hide();
  };

  const performSecondAction = () => {
    handleSecondAction?.();
    modal.hide();
  };

  const afterTransitionLeave = () => {
    modal.remove();
    afterClose?.();
  };

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={afterTransitionLeave}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative max-w-xl transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <IntegrationsSettings onlyShowButton={false} />
                <div className="w-100 pv2 flex items-center relative mv3 h2">
                  <div className="absolute z-0 w-100 tc bg-black-20 h--line"></div>
                  <div className="center z-2 bg-white self-center">
                    <span className="db ph2 pb1 lightest-gray f6">or</span>
                  </div>
                </div>
                <div>
                  <div className="mt-3 text-left text-sm sm:mt-5">
                    <div className="mt-5 sm:mt-6">{message}</div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-evenly gap-2">
                  <div className="py-2 mt-4 text-center w-full">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-sans text-black shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                      onClick={performAction}
                      tabIndex={0}
                    >
                      <span className="flex">
                        {' '}
                        <svg className="w1 mr2" viewBox="0 0 358 322">
                          <path
                            fill="#263238"
                            fillRule="evenodd"
                            d="M358 232.196V25c0-13.8-11.19-25-24.996-25H24.996C11.194 0 0 11.192 0 25v272c0 13.8 11.19 25 24.996 25h243.2L358 232.196zM74 69c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zm209 0c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zm-156.866 97.96v17.28c3.84-.48 7.92-.72 12.24-.72 5.12 0 9.88.68 14.28 2.04 4.4 1.36 8.2 3.44 11.4 6.24 3.2 2.8 5.76 6.24 7.68 10.32s2.88 8.76 2.88 14.04c0 5.12-1 9.72-3 13.8s-4.68 7.52-8.04 10.32c-3.36 2.8-7.28 4.96-11.76 6.48-4.48 1.52-9.2 2.28-14.16 2.28-11.68 0-20.56-3.48-26.64-10.44-6.08-6.96-9.28-15.96-9.6-27h-20.4c-.16 8.8 1.08 16.64 3.72 23.52 2.64 6.88 6.44 12.68 11.4 17.4 4.96 4.72 10.96 8.28 18 10.68 7.04 2.4 14.88 3.6 23.52 3.6 8 0 15.56-1.08 22.68-3.24 7.12-2.16 13.32-5.4 18.6-9.72 5.28-4.32 9.48-9.72 12.6-16.2 3.12-6.48 4.68-13.96 4.68-22.44 0-10.24-2.52-19.12-7.56-26.64-5.04-7.52-12.76-12.4-23.16-14.64v-.48c6.72-3.04 12.32-7.52 16.8-13.44s6.72-12.72 6.72-20.4c0-7.84-1.32-14.64-3.96-20.4-2.64-5.76-6.28-10.48-10.92-14.16-4.64-3.68-10.12-6.44-16.44-8.28-6.32-1.84-13.16-2.76-20.52-2.76-8.48 0-15.96 1.36-22.44 4.08-6.48 2.72-11.88 6.48-16.2 11.28-4.32 4.8-7.64 10.56-9.96 17.28-2.32 6.72-3.64 14.16-3.96 22.32h20.4c0-4.96.64-9.68 1.92-14.16 1.28-4.48 3.24-8.4 5.88-11.76 2.64-3.36 6-6.04 10.08-8.04 4.08-2 8.84-3 14.28-3 8.64 0 15.84 2.28 21.6 6.84 5.76 4.56 8.64 11.4 8.64 20.52 0 4.48-.88 8.48-2.64 12-1.76 3.52-4.12 6.44-7.08 8.76-2.96 2.32-6.4 4.08-10.32 5.28-3.92 1.2-8.04 1.8-12.36 1.8h-6.72c-.64 0-1.36-.08-2.16-.24zm150.56 97.2V94h-15.6c-1.12 6.4-3.2 11.68-6.24 15.84s-6.76 7.44-11.16 9.84c-4.4 2.4-9.32 4.04-14.76 4.92-5.44.88-11.04 1.32-16.8 1.32v16.32h44.16v121.92h20.4z"
                          />
                        </svg>
                        Google Calendar
                      </span>
                    </button>
                  </div>
                  <div className="py-2 mt-4 text-center w-full">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-sans text-black shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                      onClick={performSecondAction}
                      tabIndex={0}
                    >
                      <span className="flex">
                        {' '}
                        <svg className="w1 mr2" viewBox="0 0 170 170">
                          <path d="M150.4 130.3c-2.5 5.6-5.4 10.8-8.7 15.6-4.6 6.4-8.4 11-11.3 13.5-4.4 4-9.2 6.2-14.4 6.3-3.7 0-8-1-13.3-3.2-5.2-2-10-3-14.3-3-4.6 0-9.5 1-14.8 3-5.2 2.2-9.5 3.3-12.7 3.4-5 .2-10-2-15-6.5-3-2.8-7-7.4-11.6-14-5-7.2-9.2-15.4-12.4-24.7-3.5-10-5.2-20-5.2-29.4 0-11 2.3-20.2 7-28 3.7-6.4 8.6-11.3 14.8-15s12.8-5.5 20-5.6c3.8 0 9 1.2 15.3 3.6C80.2 48.8 84.3 50 86 50c1.3 0 6-1.4 13.6-4.3 7.2-2.6 13.4-3.7 18.4-3.2 13.6 1 24 6.4 30.7 16-12.3 7.5-18.3 17.8-18.2 31 0 10.4 3.8 19 11.2 26 3.3 3 7 5.5 11.2 7.2l-2.8 7.5zM119 7.3c0 8-3 15.6-8.8 22.6-7 8.2-15.7 13-25 12.3l-.2-3c0-8 3.4-16.2 9.4-23 3-3.4 6.8-6.3 11.4-8.6 4.7-2.2 9-3.5 13-3.7.3 1 .3 2.2.3 3.2z"></path>
                        </svg>
                        Apple iCalendar (iCal)
                      </span>
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export { AddToCalendarModal };
