import ChangeEmail from '../ChangeEmail/ChangeEmail';
import ChangePassword from '../ChangePassword/ChangePassword';
import Integrations from '../Integrations/Integrations';
import Pseudonym from '../Onboarding/modalFrames/Pseudonym/Pseudonym';
import UserPreferences from '../UserPreferences/UserPreferences';

const SettingsAccount = () => (
  <div className="mt-4">
    <div className="border-b border-gray-900/10 pb-2">
      <Pseudonym />
    </div>
    <div className="border-b border-gray-900/10">
      <Integrations />
    </div>
    <div className="border-b border-gray-900/10">
      <UserPreferences />
    </div>
    <div className="border-b border-gray-900/10 pb-12">
      <ChangeEmail />
    </div>
    <div className="border-b border-gray-900/10 pb-12">
      <ChangePassword />
    </div>
  </div>
);

export default SettingsAccount;
