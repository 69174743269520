import { useEffect } from 'react';
import { useLocation } from 'react-router';

// Triggers an Angular digest cycle each time the location changes
//
// https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$apply
export function useReactInNgRootScopeUpdate() {
  const location = useLocation();

  useEffect(() => {
    window.Rollbar.debug(`Navigated to ${JSON.stringify(location)}`);
    const $rootScope = window.angular.element(document.body).injector().get('$rootScope');
    if ($rootScope) {
      $rootScope.$apply();
    }
  }, [location]);
}
